'use client';

import styles from '@/view/styles/pages/home/Home.module.scss';
import React from 'react';
import DesktopTopBar from '@/view/components/TopBar/DesktopTopBar';
import TopBar from '@/view/components/TopBar/TopBar';
import HomePageContent from './tabs/HomePageContent';

export enum FILTERS {
  ALL = 0,
  POSTS,
  MUSIC,
  VIDEO,
  ARTWORK,
  // SHORTS,
}

export const Home = () => {
  return (
    <>
      <TopBar />
      <DesktopTopBar />
      <div className={styles.root}>
        <div className={styles.homeTab}>
          <HomePageContent />
        </div>
      </div>
    </>
  );
};

export default Home;
