import styles from '@/view/styles/pages/home/tabs/blocs/FeaturedTab/FeaturedTrendingTab.module.scss';
import React from 'react';
import {graphql, useLazyLoadQuery, usePagination} from '@/kits/relay-kit/src';
import UserCard from '@/view/components/UserCard/UserCard';
import InfiniteScrollContainer from '@/view/components/InfiniteScrollContainer/InfiniteScrollContainer';
import EmptyProfileCard from '@/view/components/EmptyProfileCards/EmptyProfileCard';
import type {
  FeaturedCreatorsTabAllAccountsQuery$data,
  FeaturedCreatorsTabAllAccountsQuery as FeaturedCreatorsTabAllAccountsQueryType,
} from './__generated__/FeaturedCreatorsTabAllAccountsQuery.graphql';
import {
  FeaturedCreatorsTabAllAccountsPaginated$data,
  FeaturedCreatorsTabAllAccountsPaginated$key,
} from './__generated__/FeaturedCreatorsTabAllAccountsPaginated.graphql';
import {FeaturedCreatorsTabAllAccountsPaginatedQuery} from './__generated__/FeaturedCreatorsTabAllAccountsPaginatedQuery.graphql';

const FeaturedCreatorsTabAllAccountsQuery = graphql`
  query FeaturedCreatorsTabAllAccountsQuery {
    accountsCollection(orderBy: {created_at: DescNullsLast}, first: 30) {
      edges {
        node {
          id
          nodeId
        }
      }
    }
    ...FeaturedCreatorsTabAllAccountsPaginated
  }
`;

export default function FeaturedCreatorsTab() {
  const {data, isLoading} =
    useLazyLoadQuery<FeaturedCreatorsTabAllAccountsQueryType>(
      FeaturedCreatorsTabAllAccountsQuery,
      {}
    );
  return (
    !isLoading && (
      <div className={styles.root} data-fill="true">
        <Users users={data} />
      </div>
    )
  );
}

const FeaturedCreatorsTabAllAccountsPaginated = graphql`
  fragment FeaturedCreatorsTabAllAccountsPaginated on Query
  @argumentDefinitions(
    first: {type: "Int", defaultValue: 30}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "FeaturedCreatorsTabAllAccountsPaginatedQuery") {
    accountsCollection(
      orderBy: {created_at: DescNullsLast}
      first: $first
      after: $after
    )
      @connection(
        key: "FeaturedCreatorsTabAllAccountsPaginated_accountsCollection"
      ) {
      edges {
        node {
          nodeId
          id
          identities {
            profilesCollection {
              edges {
                node {
                  nodeId
                  id
                  username
                }
              }
            }
          }
          ...UserCardFragment
        }
      }
    }
  }
`;

const Users = ({
  users,
}: {
  users: FeaturedCreatorsTabAllAccountsQuery$data | null | undefined;
}) => {
  const {
    data: paginatedUsers,
    hasNext,
    loadNext,
    isLoadingNext,
    isLoading,
  } = usePagination<
    FeaturedCreatorsTabAllAccountsPaginatedQuery,
    FeaturedCreatorsTabAllAccountsPaginated$key
  >(FeaturedCreatorsTabAllAccountsPaginated, users);

  return (
    <>
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading
            ? () => {
                loadNext(20);
              }
            : undefined
        }
        styleOverrides={{
          width: '100%',
          height: '100px',
          gridColumn: '1 / -1',
        }}
      >
        {(paginatedUsers?.accountsCollection?.edges?.length || 0) > 0 ? (
          paginatedUsers?.accountsCollection?.edges.map((edge, i) => {
            return (
              !!edge.node.identities.profilesCollection?.edges?.[0]?.node
                ?.username && (
                <div key={edge.node.id}>
                  <UserCard
                    view="mini"
                    userKey={edge.node}
                    showFollowButton
                    observe
                  />
                </div>
              )
            );
          })
        ) : (
          <EmptyProfileCard
            title="No users found"
            className={styles.empty}
            small
          />
        )}
      </InfiniteScrollContainer>
    </>
  );
};
