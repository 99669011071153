import ScrollableContainer from '@/view/components/ScrollableContainer';
import UserCard from '@/view/components/UserCard/UserCard';
import React from 'react';
import {graphql, useFragment, useLazyLoadQuery} from '@/kits/relay-kit/src';
import {useAccount} from '@/kits/account-kit/src';
import type {WhoToFollowSuggestedAccountsQuery as WhoToFollowSuggestedAccountsQueryType} from './__generated__/WhoToFollowSuggestedAccountsQuery.graphql';
import {WhoToFollowSuggestedAccountCardFragment$key} from './__generated__/WhoToFollowSuggestedAccountCardFragment.graphql';

const WhoToFollowSuggestedAccountsQuery = graphql`
  query WhoToFollowSuggestedAccountsQuery($identityId: BigInt!) {
    get_suggested_accounts(
      identity_id: $identityId
      first: 5
      filter: {identity_id: {neq: $identityId}}
    ) {
      edges {
        node {
          id
          nodeId
          ...WhoToFollowSuggestedAccountCardFragment
        }
      }
    }
  }
`;

export default function WhoToFollow({
  className,
  showFollowButton,
}: {
  showFollowButton?: boolean;
  className?: string;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();

  const identityId = user?.isLoggedIn ? user?.identityId : undefined;

  const {data} = useLazyLoadQuery<WhoToFollowSuggestedAccountsQueryType>(
    WhoToFollowSuggestedAccountsQuery,
    {
      identityId: identityId || '',
    },
    {
      skip: !identityId || identityId === '',
    }
  );

  return (
    <ScrollableContainer
      title="Who to follow"
      cols={3}
      fullWidth
      fullWidthPadding="md"
      className={className}
    >
      {data?.get_suggested_accounts?.edges?.map(user => {
        return (
          <Card
            key={user.node.id}
            userAccountKey={user.node}
            showFollowButton={showFollowButton}
          />
        );
      })}
    </ScrollableContainer>
  );
}

const WhoToFollowSuggestedAccountCardFragment = graphql`
  fragment WhoToFollowSuggestedAccountCardFragment on accounts {
    ...UserCardFragment
  }
`;

const Card = ({
  userAccountKey,
  showFollowButton,
}: {
  userAccountKey: WhoToFollowSuggestedAccountCardFragment$key | null;
  showFollowButton?: boolean;
}) => {
  const userAccount = useFragment(
    WhoToFollowSuggestedAccountCardFragment,
    userAccountKey
  );
  return (
    <UserCard
      userKey={userAccount}
      showFollowButton={showFollowButton}
      observe
    />
  );
};
