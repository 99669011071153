/**
 * @generated SignedSource<<d1083aa277411f3c3e24d1ca7e2f93ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SuggestedAccountsQuery$variables = {};
export type SuggestedAccountsQuery$data = {
  readonly featured_accountsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accounts: {
          readonly id: string;
          readonly nodeId: string;
          readonly " $fragmentSpreads": FragmentRefs<"SuggestedAccountsItemFragment">;
        };
        readonly id: string;
      };
    }>;
  } | null;
};
export type SuggestedAccountsQuery = {
  response: SuggestedAccountsQuery$data;
  variables: SuggestedAccountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "public_key_hash",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SuggestedAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "featured_accountsConnection",
        "kind": "LinkedField",
        "name": "featured_accountsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "featured_accountsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "featured_accounts",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "accounts",
                    "kind": "LinkedField",
                    "name": "accounts",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SuggestedAccountsItemFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SuggestedAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "featured_accountsConnection",
        "kind": "LinkedField",
        "name": "featured_accountsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "featured_accountsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "featured_accounts",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "accounts",
                    "kind": "LinkedField",
                    "name": "accounts",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "identities",
                        "kind": "LinkedField",
                        "name": "identities",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "profilesConnection",
                            "kind": "LinkedField",
                            "name": "profilesCollection",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "profilesEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "profiles",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v0/*: any*/),
                                      (v1/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "username",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "avatar_uri",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "bio",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "banner_uri",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "website",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "tezos_profilesConnection",
                                        "kind": "LinkedField",
                                        "name": "tezos_profilesCollection",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "tezos_profilesEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "tezos_profiles",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v0/*: any*/),
                                                  (v1/*: any*/),
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "description",
                                                    "storageKey": null
                                                  },
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "logo",
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "magic_accountsConnection",
                        "kind": "LinkedField",
                        "name": "magic_accountsCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "magic_accountsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "magic_accounts",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v2/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "beacon_accountsConnection",
                        "kind": "LinkedField",
                        "name": "beacon_accountsCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "beacon_accountsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "beacon_accounts",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v2/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "teztok_accountsConnection",
                        "kind": "LinkedField",
                        "name": "teztok_accountsCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "teztok_accountsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "teztok_accounts",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v2/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "82168353d6be472e37539e1800064e54",
    "id": null,
    "metadata": {},
    "name": "SuggestedAccountsQuery",
    "operationKind": "query",
    "text": "query SuggestedAccountsQuery {\n  featured_accountsCollection {\n    edges {\n      node {\n        id\n        accounts {\n          id\n          nodeId\n          ...SuggestedAccountsItemFragment\n        }\n        nodeId\n      }\n    }\n  }\n}\n\nfragment SuggestedAccountsItemFragment on accounts {\n  id\n  nodeId\n  type\n  identities {\n    id\n    nodeId\n    profilesCollection {\n      edges {\n        node {\n          id\n          nodeId\n          username\n          avatar_uri\n          name\n        }\n      }\n    }\n  }\n  magic_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n      }\n    }\n  }\n  beacon_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n      }\n    }\n  }\n  teztok_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n      }\n    }\n  }\n  ...UserHoverCardFragment\n}\n\nfragment UserHoverCardFragment on accounts {\n  id\n  nodeId\n  type\n  identities {\n    id\n    nodeId\n    profilesCollection {\n      edges {\n        node {\n          id\n          nodeId\n          username\n          name\n          bio\n          avatar_uri\n          banner_uri\n          website\n          tezos_profilesCollection {\n            edges {\n              node {\n                id\n                nodeId\n                description\n                logo\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  magic_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n      }\n    }\n  }\n  beacon_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n      }\n    }\n  }\n  teztok_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "81efb2689ac996efe520c01071038e9e";

export default node;
