/**
 * @generated SignedSource<<37200af228fec46eae1fad8cfb329185>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HomeFtueStepsQuery$variables = {
  accountId: string;
  identityId: string;
};
export type HomeFtueStepsQuery$data = {
  readonly collected: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  } | null;
  readonly created: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  } | null;
  readonly followed: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly followee_id: string;
      };
    }>;
  } | null;
};
export type HomeFtueStepsQuery = {
  response: HomeFtueStepsQuery$data;
  variables: HomeFtueStepsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "identityId"
  }
],
v1 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "eq",
      "variableName": "accountId"
    }
  ],
  "kind": "ObjectValue",
  "name": "account_id"
},
v2 = [
  {
    "fields": [
      (v1/*: any*/),
      {
        "kind": "Literal",
        "name": "type",
        "value": {
          "eq": "create_tezos_token"
        }
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "eventsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "events",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "fields": [
      (v1/*: any*/),
      {
        "kind": "Literal",
        "name": "type",
        "value": {
          "in": [
            "purchase_tezos_token",
            "pending_purchase_tezos_token",
            "mint_tezos_token",
            "pending_mint_tezos_token",
            "collect_for_free",
            "mint_for_free"
          ]
        }
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  }
],
v6 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "identityId"
          }
        ],
        "kind": "ObjectValue",
        "name": "follower_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "followee_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "eventsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "events",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeFtueStepsQuery",
    "selections": [
      {
        "alias": "created",
        "args": (v2/*: any*/),
        "concreteType": "eventsConnection",
        "kind": "LinkedField",
        "name": "eventsCollection",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": "collected",
        "args": (v5/*: any*/),
        "concreteType": "eventsConnection",
        "kind": "LinkedField",
        "name": "eventsCollection",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": "followed",
        "args": (v6/*: any*/),
        "concreteType": "followsConnection",
        "kind": "LinkedField",
        "name": "followsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "followsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "follows",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HomeFtueStepsQuery",
    "selections": [
      {
        "alias": "created",
        "args": (v2/*: any*/),
        "concreteType": "eventsConnection",
        "kind": "LinkedField",
        "name": "eventsCollection",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      {
        "alias": "collected",
        "args": (v5/*: any*/),
        "concreteType": "eventsConnection",
        "kind": "LinkedField",
        "name": "eventsCollection",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      {
        "alias": "followed",
        "args": (v6/*: any*/),
        "concreteType": "followsConnection",
        "kind": "LinkedField",
        "name": "followsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "followsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "follows",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c4eee3926524549a747d59b93603ff7",
    "id": null,
    "metadata": {},
    "name": "HomeFtueStepsQuery",
    "operationKind": "query",
    "text": "query HomeFtueStepsQuery(\n  $accountId: BigInt!\n  $identityId: BigInt!\n) {\n  created: eventsCollection(first: 1, filter: {type: {eq: create_tezos_token}, account_id: {eq: $accountId}}) {\n    edges {\n      node {\n        id\n        nodeId\n      }\n    }\n  }\n  collected: eventsCollection(first: 3, filter: {type: {in: [purchase_tezos_token, pending_purchase_tezos_token, mint_tezos_token, pending_mint_tezos_token, collect_for_free, mint_for_free]}, account_id: {eq: $accountId}}) {\n    edges {\n      node {\n        id\n        nodeId\n      }\n    }\n  }\n  followed: followsCollection(first: 5, filter: {follower_id: {eq: $identityId}}) {\n    edges {\n      node {\n        followee_id\n        nodeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0750f4a620beb7977198ccd53acc781";

export default node;
