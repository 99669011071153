import FeedCard from '@/view/components/FeedCard/FeedCard';
import React, {Suspense} from 'react';
import {graphql, useLazyLoadQuery, usePagination} from '@/kits/relay-kit/src';
import Skeleton from '@/view/components/Skeleton';
import {useAccount} from '@/kits/account-kit/src';
import {useCreateStatus} from '@/state/hooks/createStatus';
import InfiniteScrollContainer from '@/view/components/InfiniteScrollContainer/InfiniteScrollContainer';
import {FILTERS} from '../..';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import EmptyProfileCard from '@/view/components/EmptyProfileCards/EmptyProfileCard';
import {FollowingTabFilteredFeedEventsPaginated$key} from './__generated__/FollowingTabFilteredFeedEventsPaginated.graphql';
import {FollowingTabFilteredFeedEventsPaginatedQuery} from './__generated__/FollowingTabFilteredFeedEventsPaginatedQuery.graphql';
import type {FollowingTabFilteredFeedEventsQuery as FollowingTabFilteredFeedEventsQueryType} from './__generated__/FollowingTabFilteredFeedEventsQuery.graphql';

const getFilter = (filter: FILTERS) => {
  switch (filter) {
    case FILTERS.POSTS:
      return 'post';
    case FILTERS.MUSIC:
      return 'audio';
    case FILTERS.VIDEO:
      return 'video';
    case FILTERS.ARTWORK:
      return 'image';
    default:
      return '';
  }
};

const FollowingTabFilteredFeedEventsQuery = graphql`
  query FollowingTabFilteredFeedEventsQuery($type: String!, $identityId: Int!) {
    ...FollowingTabFilteredFeedEventsPaginated
      @arguments(type: $type, identityId: $identityId)
  }
`;

export default function FollowingTabFilteredFeed({filter}: {filter: FILTERS}) {
  const {getAccount} = useAccount();
  const user = getAccount();

  const identityId = user.isLoggedIn ? user.identityId : undefined;

  const {data, retry, error} =
    useLazyLoadQuery<FollowingTabFilteredFeedEventsQueryType>(
      FollowingTabFilteredFeedEventsQuery,
      {
        type: getFilter(filter),
        identityId: identityId ? Number(identityId) : 1,
      },
      {
        skip: !identityId || identityId === '',
      }
    );

  const {status} = useCreateStatus();

  React.useEffect(() => {
    if (status === 'done') {
      const timeout = setTimeout(() => {
        retry();
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [status, retry]);

  return !error && <Posts posts={data} filter={filter} />;
}

const FollowingTabFilteredFeedEventsPaginated = graphql`
  fragment FollowingTabFilteredFeedEventsPaginated on Query
  @argumentDefinitions(
    identityId: {type: "Int!"}
    type: {type: "String!"}
    first: {type: "Int", defaultValue: 10}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "FollowingTabFilteredFeedEventsPaginatedQuery") {
    get_following_tab_filtered_events(
      identity_id: $identityId
      type: $type
      orderBy: {created_at: DescNullsLast}
      first: $first
      after: $after
    )
      @connection(
        key: "FollowingTabFilteredFeedEventsPaginated_get_following_tab_filtered_events"
      ) {
      edges {
        node {
          id
          nodeId
          ...FeedCardEventFragment @arguments(includeToken: true)
        }
      }
    }
  }
`;

const Posts = ({
  posts,
  filter,
}: {
  posts: FollowingTabFilteredFeedEventsPaginated$key | null | undefined;
  filter: FILTERS;
}) => {
  const {data, hasNext, loadNext, isLoadingNext, isLoading} = usePagination<
    FollowingTabFilteredFeedEventsPaginatedQuery,
    FollowingTabFilteredFeedEventsPaginated$key
  >(FollowingTabFilteredFeedEventsPaginated, posts);

  const {isMobile} = useBreakpoint();

  return (
    <>
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading
            ? () => {
                hasNext && loadNext(10);
              }
            : undefined
        }
        styleOverrides={{
          position: 'absolute',
          minHeight: '50vh',
          bottom: '100vh',
          left: '0px',
          right: '0px',
          opacity: 0,
          zIndex: '-1',
        }}
      >
        {data?.get_following_tab_filtered_events?.edges?.map((edge, i) => {
          const isLast =
            i ===
            (data.get_following_tab_filtered_events?.edges?.length || 0) - 1;
          return (
            <Suspense
              key={(edge.node.id || edge.node.nodeId || '') + i + '-suspense'}
              fallback={
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={'min(100vw, 600px)'}
                  height={780}
                  styles={{
                    maxWidth: '100vw',
                  }}
                />
              }
            >
              <FeedCard
                eventKey={edge.node}
                key={(edge.node.id || edge.node.nodeId || '') + i}
              />
              {!isLast && (
                <hr
                  key={
                    (edge.node.id || edge.node.nodeId || '') + i + '-separator'
                  }
                />
              )}
            </Suspense>
          );
        })}
        {!data?.get_following_tab_filtered_events?.edges.length && (
          <EmptyProfileCard
            title={`There is no ${(FILTERS[filter] === 'POSTS'
              ? FILTERS[filter].slice(0, -1)
              : FILTERS[filter]
            ).toLocaleLowerCase()} created yet.`}
            small
          />
        )}
      </InfiniteScrollContainer>
      {(isLoadingNext || isLoading) && (
        <Skeleton
          variant="rect"
          animation="wave"
          width={'min(100vw, 600px)'}
          height={780}
          styles={{
            maxWidth: '100vw',
          }}
        />
      )}
    </>
  );
};
