/**
 * @generated SignedSource<<1dc3de0acd7f27ce88f2b307f1fbcbfc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeTabFeedEventsPaginated$data = {
  readonly eventsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
        readonly " $fragmentSpreads": FragmentRefs<"FeedCardEventFragment">;
      };
    }>;
  } | null;
  readonly " $fragmentType": "HomeTabFeedEventsPaginated";
};
export type HomeTabFeedEventsPaginated$key = {
  readonly " $data"?: HomeTabFeedEventsPaginated$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeTabFeedEventsPaginated">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "eventsCollection"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./HomeTabFeedEventsPaginatedQuery.graphql')
    }
  },
  "name": "HomeTabFeedEventsPaginated",
  "selections": [
    {
      "alias": "eventsCollection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "type": {
              "eq": "create_tezos_token"
            }
          }
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "created_at": "DescNullsLast"
          }
        }
      ],
      "concreteType": "eventsConnection",
      "kind": "LinkedField",
      "name": "__HomeTabFeedEventsPaginated_eventsCollection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "eventsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "events",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nodeId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "includeToken",
                      "value": true
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "FeedCardEventFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__HomeTabFeedEventsPaginated_eventsCollection_connection(filter:{\"type\":{\"eq\":\"create_tezos_token\"}},orderBy:{\"created_at\":\"DescNullsLast\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "57f1ef5665a0bd95d32448640181643a";

export default node;
