import FeedCard from '@/view/components/FeedCard/FeedCard';
import React, {Suspense} from 'react';
import {
  commitLocalStoreUpdate,
  graphql,
  useLazyLoadQuery,
  usePagination,
} from '@/kits/relay-kit/src';
import Skeleton from '@/view/components/Skeleton';
import {useAccount} from '@/kits/account-kit/src';
import {useCreateStatus} from '@/state/hooks/createStatus';
import InfiniteScrollContainer from '@/view/components/InfiniteScrollContainer/InfiniteScrollContainer';
import {useOnchainActionStatus} from '@/state/hooks/onChainActionStatus';
import EmptyProfileCard from '@/view/components/EmptyProfileCards/EmptyProfileCard';
import HomeFtueSteps from '@/view/components/FTUESteps/HomeFtueSteps';
import type {HomeTabFeedEventsQuery as HomeTabFeedEventsQueryType} from './__generated__/HomeTabFeedEventsQuery.graphql';
import {HomeTabFeedEventsPaginated$key} from './__generated__/HomeTabFeedEventsPaginated.graphql';
import {HomeTabFeedEventsPaginatedQuery} from './__generated__/HomeTabFeedEventsPaginatedQuery.graphql';

const HomeTabFeedEventsQuery = graphql`
  query HomeTabFeedEventsQuery {
    ...HomeTabFeedEventsPaginated
  }
`;

export default function HomeTabFeed() {
  const {getAccount} = useAccount();
  const user = getAccount();

  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const {data, retry} = useLazyLoadQuery<HomeTabFeedEventsQueryType>(
    HomeTabFeedEventsQuery
  );

  const {status} = useCreateStatus();
  const {actions} = useOnchainActionStatus();

  React.useEffect(() => {
    if (
      status === 'done' ||
      (actions.length > 0 && actions.every(a => a.status === 'done'))
    ) {
      const timeout = setTimeout(() => {
        retry();
        commitLocalStoreUpdate(updater => {
          updater
            .get(
              `client:root:eventsCollection(filter:{"account_id":{"eq":"${accountId}"},"type":{"neq":"follow"}},first:5,orderBy:{"created_at":"DescNullsLast"})`
            )
            ?.invalidateRecord();
        });
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [status, actions, retry, accountId]);

  return <Posts posts={data} />;
}

const HomeTabFeedEventsPaginated = graphql`
  fragment HomeTabFeedEventsPaginated on Query
  @argumentDefinitions(
    first: {type: "Int", defaultValue: 10}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "HomeTabFeedEventsPaginatedQuery") {
    eventsCollection(
      orderBy: {created_at: DescNullsLast}
      filter: {type: {eq: create_tezos_token}}
      first: $first
      after: $after
    ) @connection(key: "HomeTabFeedEventsPaginated_eventsCollection") {
      edges {
        node {
          nodeId
          id
          ...FeedCardEventFragment @arguments(includeToken: true)
        }
      }
    }
  }
`;

const Posts = ({
  posts,
}: {
  posts: HomeTabFeedEventsPaginated$key | null | undefined;
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();

  const {data, hasNext, loadNext, isLoadingNext, isLoading} = usePagination<
    HomeTabFeedEventsPaginatedQuery,
    HomeTabFeedEventsPaginated$key
  >(HomeTabFeedEventsPaginated, posts);

  if (data?.eventsCollection?.edges.length === 0) {
    return <EmptyProfileCard title="No posts yet, create something!" small />;
  }

  return (
    <>
      {user.isLoggedIn && (
        <Suspense
          fallback={
            <Skeleton
              variant="rect"
              animation="wave"
              borderRadius={10}
              height={420}
              styles={{
                maxWidth: '600px',
              }}
            />
          }
        >
          <HomeFtueSteps />
        </Suspense>
      )}
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading
            ? () => {
                hasNext && loadNext(10);
              }
            : undefined
        }
        styleOverrides={{
          position: 'absolute',
          minHeight: '50vh',
          bottom: '100vh',
          left: '0px',
          right: '0px',
          opacity: 0,
          zIndex: '-1',
        }}
      >
        {data?.eventsCollection?.edges?.map((edge, i) => {
          const isLast = i === (data.eventsCollection?.edges?.length || 0) - 1;
          return (
            <Suspense
              key={(edge.node.id || edge.node.nodeId || '') + i + '-suspense'}
              fallback={
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={'min(100vw, 600px)'}
                  height={780}
                  styles={{
                    maxWidth: '100vw',
                  }}
                />
              }
            >
              <FeedCard
                eventKey={edge.node}
                key={(edge.node.id || edge.node.nodeId || '') + i}
              />
              {!isLast && (
                <hr
                  key={
                    (edge.node.id || edge.node.nodeId || '') + i + '-separator'
                  }
                />
              )}
            </Suspense>
          );
        })}
      </InfiniteScrollContainer>
      {(isLoadingNext || isLoading) && (
        <Skeleton
          variant="rect"
          animation="wave"
          width={'min(100vw, 600px)'}
          height={780}
          styles={{
            maxWidth: '100vw',
          }}
        />
      )}
    </>
  );
};
