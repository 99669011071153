import styles from '@/view/styles/pages/home/tabs/blocs/FeaturedTab/FeaturedTrendingTab.module.scss';
import React from 'react';
import {graphql, useLazyLoadQuery, usePagination} from '@/kits/relay-kit/src';
import PostCard from '@/view/components/PostCard/PostCard';
import InfiniteScrollContainer from '@/view/components/InfiniteScrollContainer/InfiniteScrollContainer';
import EmptyProfileCard from '@/view/components/EmptyProfileCards/EmptyProfileCard';
import type {FeaturedTypeTabTokensQuery as FeaturedTypeTabTokensQueryType} from './__generated__/FeaturedTypeTabTokensQuery.graphql';
import {FeaturedTypeTabTokensPaginated$key} from './__generated__/FeaturedTypeTabTokensPaginated.graphql';
import {FeaturedTypeTabTokensPaginatedQuery} from './__generated__/FeaturedTypeTabTokensPaginatedQuery.graphql';

const FeaturedTypeTabTokensQuery = graphql`
  query FeaturedTypeTabTokensQuery(
    $types: [enum_token_type!]
    $orderBy: OrderByDirection
  ) {
    ...FeaturedTypeTabTokensPaginated
      @arguments(types: $types, orderBy: $orderBy)
  }
`;

const FeaturedTypeTabTokensPaginated = graphql`
  fragment FeaturedTypeTabTokensPaginated on Query
  @argumentDefinitions(
    types: {type: "[enum_token_type!]"}
    orderBy: {type: "OrderByDirection"}
    first: {type: "Int", defaultValue: 30}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "FeaturedTypeTabTokensPaginatedQuery") {
    tezos_tokensCollection(
      filter: {type: {in: $types}}
      orderBy: {created_at: $orderBy}
      first: $first
      after: $after
    )
      @connection(
        key: "FeaturedTypeTabTokensPaginated_tezos_tokensCollection"
      ) {
      edges {
        node {
          nodeId
          id
          ...PostCardTokenFragment @arguments(includeDefault: true)
        }
      }
    }
  }
`;

export default function FeaturedTypeTab({
  type,
}: {
  type: ('image' | 'audio' | 'video' | 'post')[];
}) {
  const {data: recentReleases, isLoading: recentReleasesLoading} =
    useLazyLoadQuery<FeaturedTypeTabTokensQueryType>(
      FeaturedTypeTabTokensQuery,
      {
        orderBy: 'DescNullsLast',
        types: type,
      }
    );
  return (
    !recentReleasesLoading && (
      <div className={styles.root}>
        <Posts tokens={recentReleases} />
      </div>
    )
  );
}

const Posts = ({
  tokens,
}: {
  tokens: FeaturedTypeTabTokensPaginated$key | null | undefined;
}) => {
  const {
    data: paginatedTokens,
    hasNext,
    loadNext,
    isLoadingNext,
    isLoading,
  } = usePagination<
    FeaturedTypeTabTokensPaginatedQuery,
    FeaturedTypeTabTokensPaginated$key
  >(FeaturedTypeTabTokensPaginated, tokens);

  return (
    <>
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading
            ? () => {
                loadNext(20);
              }
            : undefined
        }
        styleOverrides={{
          width: '100%',
          height: '100px',
          gridColumn: '1 / -1',
        }}
      >
        {(paginatedTokens?.tezos_tokensCollection?.edges?.length || 0) > 0 ? (
          paginatedTokens?.tezos_tokensCollection?.edges.map((edge, i) => {
            return (
              edge.node && (
                <div key={edge.node.id}>
                  <PostCard view="default-library" tokenKey={edge.node} />
                </div>
              )
            );
          })
        ) : (
          <EmptyProfileCard
            title="No items found"
            className={styles.empty}
            small
          />
        )}
      </InfiniteScrollContainer>
    </>
  );
};
