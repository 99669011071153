import React from 'react';
import styles from '@/view/styles/components/Greeting.module.scss';
import clsx from 'clsx';
import Link from 'next/link';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import {useAccount} from '@/kits/account-kit/src';
import type {GreetingQuery as GreetingQueryType} from './__generated__/GreetingQuery.graphql';

const GreetingQuery = graphql`
  query GreetingQuery($id: BigInt!) {
    profilesCollection(filter: {identity_id: {eq: $id}}) {
      edges {
        node {
          username
        }
      }
    }
  }
`;

export default function Greeting({className}: {className?: string}) {
  const {getAccount} = useAccount();

  const user = getAccount();
  const identityId = user?.isLoggedIn ? user.identityId : undefined;

  const {data} = useLazyLoadQuery<GreetingQueryType>(
    GreetingQuery,
    {
      id: identityId || '',
    },
    {
      skip: !identityId || identityId === '',
    }
  );

  const edge = data?.profilesCollection?.edges?.[0];
  const account = edge?.node;
  if (user.isLoggedIn && account?.username) {
    return (
      <Link
        href={`/${account.username || user?.accountId}`}
        className={clsx(styles.root, className)}
      >
        Hi, {account.username}!
      </Link>
    );
  } else if (user.isLoggedIn) {
    return (
      <Link
        href={`/${user?.accountId}`}
        className={clsx(styles.root, className)}
      >
        Hello!
      </Link>
    );
  }

  return <div className={clsx(styles.root, className)}>Hello!</div>;
}
