import styles from '@/view/styles/pages/home/tabs/blocs/FeaturedTab/FeaturedAllTab.module.scss';
import React, {Suspense} from 'react';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import ScrollableContainer from '@/view/components/ScrollableContainer';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import PostCard from '@/view/components/PostCard/PostCard';
import UserCard from '@/view/components/UserCard/UserCard';
import InfiniteLoader from '@/view/components/InfiniteLoader';
import {ErrorBoundary} from 'react-error-boundary';
import Button from '@/view/components/Button';
import Router from 'next/router';
import {usePlaceholderIds} from '@/view/placeholderIds';
import Skeleton from '@/view/components/Skeleton';
import type {FeaturedAllTabPlaylistsQuery as FeaturedAllTabPlaylistsQueryType} from './__generated__/FeaturedAllTabPlaylistsQuery.graphql';
import type {FeaturedAllTabProfilesQuery as FeaturedAllTabProfilesQueryType} from './__generated__/FeaturedAllTabProfilesQuery.graphql';
import type {FeaturedAllTabRecentlyAddedTokensQuery as FeaturedAllTabRecentlyAddedTokensQueryType} from './__generated__/FeaturedAllTabRecentlyAddedTokensQuery.graphql';

const COLLECTION_1 = '1792';
const COLLECTION_2 = '1801';
const COLLECTION_3 = '1889';
const COLLECTION_4 = '1911';
const Creators_We_Love = [
  '1',
  '4',
  '7',
  '8',
  '28',
  '20',
  '36',
  '63',
  '79',
  '105',
  '108',
  '119',
  '121',
  '132',
  '155',
  '239',
  '317',
  '334',
  '357',
  '397',
  '398',
  '403',
  '405',
  '468',
  '505',
  '524',
  '588',
  '615',
];
const COLLECTION_5 = '1415';
const COLLECTION_6 = '1913';
const COLLECTION_7 = '1921';
const COLLECTION_8 = '1925';

export default function FeaturedAllTab() {
  return (
    <div className={styles.root}>
      <FeartuedPlaylistsContainer
        playlists={[
          {
            id: COLLECTION_1,
            view: 'default-library',
          },
          {
            id: COLLECTION_2,
            view: 'default-library',
          },
          {
            id: COLLECTION_3,
            view: 'default-library',
          },
          {
            id: COLLECTION_4,
            view: 'default-library',
          },
        ]}
      />
      <Suspense fallback={<InfiniteLoader height={300} loaderSize={42} />}>
        <FeaturedAccounts />
      </Suspense>
      <FeartuedPlaylistsContainer
        playlists={[
          {
            id: COLLECTION_5,
            view: 'music',
          },
          {
            id: COLLECTION_6,
            view: 'default-library',
          },
          {
            id: COLLECTION_7,
            view: 'default-library',
          },
          {
            id: COLLECTION_8,
            view: 'default-library',
          },
        ]}
      />
      <Suspense fallback={<InfiniteLoader height={300} loaderSize={42} />}>
        <FeaturedDiscover />
      </Suspense>
    </div>
  );
}

const FeartuedPlaylistsContainer = ({
  playlists,
}: {
  playlists: {
    id: string;
    view:
      | 'default'
      | 'text'
      | 'default-library'
      | 'music'
      | 'simple'
      | 'minimal';
  }[];
}) => {
  const placeholders = usePlaceholderIds(3);
  const {isMobile} = useBreakpoint();
  return (
    <ErrorBoundary
      fallback={
        <div
          style={{
            height: '330px',
            maxWidth: 'unset',
            width: '100%',
            borderRadius: '0px',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 'var(--gap-medium, 16px)',
          }}
        >
          <div>Something went wrong</div>
          <span
            style={{
              color: 'var(--textTertiary)',
              fontSize: '0.75rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              marginBottom: '16px',
            }}
          >
            Failed to load collection content, refresh to try again
          </span>
          <Button
            outlined
            onClick={() => {
              Router.reload();
            }}
          >
            <span>Retry</span>
          </Button>
        </div>
      }
    >
      <Suspense
        fallback={
          <>
            {playlists?.map((playlist, i) => {
              return (
                <div key={playlist.id} className={styles.skeletonContainer}>
                  <Skeleton
                    height={isMobile ? 22 : 32}
                    width={200}
                    animation="wave"
                    variant="text"
                  />
                  <div className={styles.skeletonRow}>
                    {placeholders.map(id => {
                      return (
                        <Skeleton
                          key={id}
                          width={playlist.view === 'music' ? 350 : 188}
                          height={playlist.view === 'music' ? 124 : 233}
                          variant="rect"
                          animation="wave"
                          borderRadius={10}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        }
      >
        <FeaturedPlaylists playlists={playlists} />
      </Suspense>
    </ErrorBoundary>
  );
};

const FeaturedAllTabPlaylistsQuery = graphql`
  query FeaturedAllTabPlaylistsQuery(
    $ids: [BigInt!]
    $orderBy: [playlists_tokensOrderBy!]
  ) {
    playlistsCollection(filter: {id: {in: $ids}}) {
      edges {
        node {
          nodeId
          id
          title
          created_at
          playlists_tokensCollection(orderBy: $orderBy) {
            edges {
              node {
                order
                tezos_tokens {
                  id
                  nodeId
                  mime_type
                  artifact_uri
                  thumbnail_uri
                  title
                  accounts {
                    id
                    nodeId
                    identities {
                      profilesCollection {
                        edges {
                          node {
                            username
                          }
                        }
                      }
                    }
                  }
                  ...PostCardTokenFragment
                    @arguments(includeDefault: true, includeMusic: true)
                }
              }
            }
          }
        }
      }
    }
  }
`;

const FeaturedPlaylists = ({
  playlists,
}: {
  playlists: {
    id: string;
    view:
      | 'default'
      | 'text'
      | 'default-library'
      | 'music'
      | 'simple'
      | 'minimal';
  }[];
}) => {
  const {data} = useLazyLoadQuery<FeaturedAllTabPlaylistsQueryType>(
    FeaturedAllTabPlaylistsQuery,
    {
      ids: playlists.map(p => p.id),
      orderBy: [
        {
          created_at: 'DescNullsLast',
        },
      ],
    },
    {
      skip: playlists.length === 0,
    }
  );

  const {isMobile} = useBreakpoint();
  return (
    data?.playlistsCollection?.edges?.length !== 0 &&
    data?.playlistsCollection?.edges
      .map(edge => edge)
      ?.sort((a, b) => {
        const aIndex = playlists.findIndex(p => p.id === a.node.id);
        const bIndex = playlists.findIndex(p => p.id === b.node.id);
        return aIndex - bIndex;
      })
      .map((edge, i) => {
        return (
          <ScrollableContainer
            key={edge.node.id + i}
            title={edge?.node?.title}
            fullWidth
            variant="transparent"
            fullWidthPadding={isMobile ? 'md' : 'lg'}
            className={styles.scrollableContainer}
          >
            {edge?.node?.playlists_tokensCollection?.edges
              ?.map(edge => edge)
              ?.sort(
                (a, b) =>
                  new Date(b?.node?.order || 0).getTime() -
                  new Date(a?.node?.order || 0).getTime()
              )
              .map((token, i) => {
                return (
                  token.node && (
                    <div key={token.node.tezos_tokens.id + i}>
                      <PostCard
                        view={playlists.find(p => p.id === edge.node?.id)?.view}
                        tokenKey={token.node.tezos_tokens}
                      />
                    </div>
                  )
                );
              })}
          </ScrollableContainer>
        );
      })
  );
};

const FeaturedAllTabProfilesQuery = graphql`
  query FeaturedAllTabProfilesQuery($ids: [BigInt!]) {
    identitiesCollection(filter: {id: {in: $ids}}) {
      edges {
        node {
          id
          nodeId
          accountsCollection {
            edges {
              node {
                id
                nodeId
                identities {
                  id
                  nodeId
                  profilesCollection {
                    edges {
                      node {
                        id
                        nodeId
                        username
                        avatar_uri
                      }
                    }
                  }
                }
                ...UserCardFragment
              }
            }
          }
        }
      }
    }
  }
`;

const FeaturedAccounts = () => {
  const {data: featuredAccounts, isLoading: featuredAccountsLoading} =
    useLazyLoadQuery<FeaturedAllTabProfilesQueryType>(
      FeaturedAllTabProfilesQuery,
      {
        ids: Creators_We_Love,
      }
    );

  const {isMobile} = useBreakpoint();

  return (
    featuredAccounts?.identitiesCollection?.edges?.length !== 0 && (
      <div className={styles.withPadding}>
        <ScrollableContainer
          title="Featured Creators"
          fullWidth
          variant="default"
          fullWidthPadding={isMobile ? 'md' : 'lg'}
          className={styles.scrollableContainer}
        >
          {featuredAccounts?.identitiesCollection?.edges
            ?.map(edge => edge)
            ?.sort((a, b) => {
              return (
                a.node.accountsCollection?.edges?.[0]?.node?.identities?.profilesCollection?.edges?.[0]?.node?.username?.localeCompare(
                  b.node.accountsCollection?.edges?.[0]?.node?.identities
                    ?.profilesCollection?.edges?.[0]?.node?.username || ''
                ) || 0
              );
            })
            .map((user, i) => {
              return (
                user.node.accountsCollection?.edges?.[0]?.node && (
                  <div key={user.node.id}>
                    <UserCard
                      view="mini"
                      userKey={user.node.accountsCollection?.edges?.[0].node}
                      showFollowButton
                      observe
                    />
                  </div>
                )
              );
            })}
        </ScrollableContainer>
      </div>
    )
  );
};

const FeaturedAllTabRecentlyAddedTokensQuery = graphql`
  query FeaturedAllTabRecentlyAddedTokensQuery(
    $types: [enum_token_type!]
    $orderBy: OrderByDirection
  ) {
    tezos_tokensCollection(
      filter: {type: {in: $types}}
      orderBy: {created_at: $orderBy}
      first: 30
    ) {
      edges {
        node {
          id
          nodeId
          ...PostCardTokenFragment @arguments(includeDefault: true)
        }
      }
    }
  }
`;

const FeaturedDiscover = () => {
  const {data: discover, isLoading: discoverLoading} =
    useLazyLoadQuery<FeaturedAllTabRecentlyAddedTokensQueryType>(
      FeaturedAllTabRecentlyAddedTokensQuery,
      {
        orderBy: 'DescNullsLast',
        types: ['audio', 'image', 'video'],
      }
    );

  const {isMobile} = useBreakpoint();
  return (
    discover?.tezos_tokensCollection?.edges?.length !== 0 && (
      <ScrollableContainer
        title="Discover"
        fullWidth
        variant="transparent"
        fullWidthPadding={isMobile ? 'md' : 'lg'}
        className={styles.scrollableContainer}
      >
        {discover?.tezos_tokensCollection?.edges.map((edge, i) => {
          return (
            edge.node && (
              <div key={edge.node.id}>
                <PostCard view="default-library" tokenKey={edge.node} />
              </div>
            )
          );
        })}
      </ScrollableContainer>
    )
  );
};
