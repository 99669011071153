/**
 * @generated SignedSource<<f3e0bdbf2501de327fa1d1cfca7980a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeFtueStepsCardFragment$data = {
  readonly id: string;
  readonly nodeId: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserCardFragment">;
  readonly " $fragmentType": "HomeFtueStepsCardFragment";
};
export type HomeFtueStepsCardFragment$key = {
  readonly " $data"?: HomeFtueStepsCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeFtueStepsCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeFtueStepsCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nodeId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserCardFragment"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "188a67eb9a026fb1b947c49883561cac";

export default node;
