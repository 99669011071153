import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styles from '@/view/styles/components/Carousel.module.scss';
import Button from './Button';
import clsx from 'clsx';
import RemixIcon from './RemixIcon';

const AUTOPLAY_INTERVAL = 5000;

export default function Carousel({
  children,
  count,
  infinite = false,
  autoPlay = false,
  autoPlayInterval = AUTOPLAY_INTERVAL,
  className,
  indicators = true,
  indicatorStyle = 'dot',
  markPreviousAsRead,
  onClickIndicator = true,
  clickToSkip = false,
  isDraggingParent,
  setIsChildDragging,
  pauseOnHover = false,
  animateIndicator = false,
}: {
  children: React.ReactNode[] | React.ReactNode;
  count: number;
  infinite?: boolean;
  autoPlay?: boolean;
  autoPlayInterval?: number;
  className?: string;
  indicators?: boolean;
  indicatorStyle?: 'dot' | 'line';
  markPreviousAsRead?: boolean;
  onClickIndicator?: boolean;
  clickToSkip?: boolean;
  isDraggingParent?: boolean;
  setIsChildDragging?: React.Dispatch<React.SetStateAction<boolean>>;
  pauseOnHover?: boolean;
  animateIndicator?: boolean;
}) {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const carouselRef = React.useRef<HTMLDivElement>(null);
  const [carouselProgress, setCarouselProgress] = React.useState(0);

  useEffect(() => {
    if (!carouselRef.current) return;

    const element = carouselRef.current;

    const scrollListener = () => {
      const windowScroll = element.scrollLeft;
      const totalWidth = element.scrollWidth - element.clientWidth;

      if (windowScroll === 0) setCarouselProgress(0);
      if (windowScroll > totalWidth) setCarouselProgress(100);
      return setCarouselProgress((windowScroll / totalWidth) * 100);
    };

    element.addEventListener('scroll', scrollListener, {passive: true});
    return () => element.removeEventListener('scroll', scrollListener);
  }, []);

  const scrollTo = useCallback(
    (direction: 'left' | 'right') => {
      if (!carouselRef.current) return;
      const element = carouselRef.current;
      const windowScroll = element.scrollLeft;
      const totalWidth = element.scrollWidth - element.clientWidth;
      const scrollAmount = totalWidth / count;
      if (direction === 'left') {
        if (infinite && windowScroll === 0) {
          element.scrollTo({left: totalWidth, behavior: 'smooth'});
          return;
        }
        element.scrollTo({
          left: windowScroll - scrollAmount,
          behavior: 'smooth',
        });
      } else {
        if (infinite && windowScroll >= totalWidth - scrollAmount) {
          element.scrollTo({left: 0, behavior: 'smooth'});
          return;
        }
        element.scrollTo({
          left: windowScroll + scrollAmount + scrollAmount / count,
          behavior: 'smooth',
        });
      }
    },
    [count, infinite]
  );

  const scrollToIndex = useCallback(
    (index: number) => {
      if (!carouselRef.current) return;
      const element = carouselRef.current;
      const totalWidth = element.scrollWidth - element.clientWidth;
      const scrollAmount = totalWidth / count;

      element.scrollTo({
        left: scrollAmount * index + scrollAmount / 2,
        behavior: 'smooth',
      });
    },
    [count]
  );

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: MouseEvent) => {
    const carousel = carouselRef.current;
    if (!carousel) return;
    setIsDragging(true);
    setStartX(e.pageX - carousel.offsetLeft);
    setScrollLeft(carousel.scrollLeft);
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;
    carousel.addEventListener('mousedown', handleMouseDown, {
      passive: true,
    });
    return () => {
      carousel.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const handleMouseLeave = () => {
    setIsDragging(false);
    setIsChildDragging?.(false);
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.style.cursor = 'grab';
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsChildDragging?.(false);
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.style.cursor = 'grab';
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDragging) return;
    if (isDraggingParent) return;
    const carousel = carouselRef.current;
    e.preventDefault();
    const x = e.pageX - carousel!.offsetLeft;
    const dragDistance = Math.abs(x - startX);
    if (dragDistance > 10) {
      setIsChildDragging?.(true);
    }
    const walk = (x - startX) * (5 - dragDistance / 100);
    carousel!.scrollLeft = scrollLeft - walk;
  };

  const [isHovering, setIsHovering] = useState(false);

  const [animationProgress, setAnimationProgress] = useState(0);

  useEffect(() => {
    if (!autoPlay) return;
    if (isHovering) return;
    const carousel = carouselRef.current;
    if (!carousel) return;
    const interval = setInterval(() => {
      if (carouselProgress > 99) {
        scrollToIndex(0);
      } else {
        scrollTo('right');
      }
    }, autoPlayInterval);

    if (isDragging || isDraggingParent || oncontextmenu) {
      clearInterval(interval);
      setAnimationProgress(0);
    }

    return () => clearInterval(interval);
  }, [
    autoPlay,
    isHovering,
    scrollTo,
    autoPlayInterval,
    isDragging,
    isDraggingParent,
    carouselProgress,
    scrollToIndex,
  ]);

  useEffect(() => {
    if (!animateIndicator) return;
    if (isHovering) return;
    const interval = setInterval(() => {
      setAnimationProgress(prev => {
        if (prev === 100) return 0;
        return prev + 1;
      });
    }, autoPlayInterval / 100 || 1000);

    if (isDragging || isDraggingParent || oncontextmenu) {
      clearInterval(interval);
      setAnimationProgress(0);
    }

    return () => clearInterval(interval);
  }, [
    autoPlayInterval,
    animateIndicator,
    isDragging,
    isDraggingParent,
    pauseOnHover,
    isHovering,
  ]);

  return (
    <div
      className={clsx(styles.root, className)}
      data-first={infinite ? undefined : carouselProgress === 0}
      data-last={infinite ? undefined : carouselProgress > 99}
      ref={rootRef}
    >
      <div
        className={styles.slides}
        ref={carouselRef}
        onClick={e => {
          if (!clickToSkip) return;
          if (carouselProgress > 99) {
            scrollToIndex(0);
          } else {
            scrollTo('right');
          }
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        style={{
          scrollBehavior: 'smooth',
        }}
        onMouseOver={() => {
          if (pauseOnHover) {
            setIsHovering(true);
          }
        }}
        onMouseOut={() => {
          if (pauseOnHover) {
            setIsHovering(false);
          }
        }}
      >
        {children}
      </div>
      <Button
        icon
        onClick={() => {
          if (count <= 1) return;
          scrollTo('left');
        }}
        className={clsx(styles.control, styles.left)}
        tooltip="Previous"
        tooltipSide="left"
      >
        <RemixIcon icon="arrow-left-s-line" size={24} />
      </Button>
      <Button
        icon
        onClick={() => {
          if (count <= 1) return;
          scrollTo('right');
        }}
        className={clsx(styles.control, styles.right)}
        tooltip="Next"
        tooltipSide="right"
      >
        <RemixIcon icon="arrow-right-s-line" size={24} />
      </Button>
      {count > 1 && indicators !== false && (
        <ScrollIndicators
          count={count}
          scrollToIndex={scrollToIndex}
          ref={carouselRef}
          indicatorStyle={indicatorStyle}
          onClickIndicator={onClickIndicator}
          markPreviousAsRead={markPreviousAsRead}
          animateIndicator={animateIndicator}
          animationProgress={animationProgress}
        />
      )}
    </div>
  );
}

const ScrollIndicators = forwardRef(
  (
    {
      count,
      scrollToIndex,
      indicatorStyle,
      onClickIndicator,
      markPreviousAsRead,
      animateIndicator,
      animationProgress,
    }: {
      count: number;
      scrollToIndex: (index: number) => void;
      indicatorStyle: 'dot' | 'line';
      onClickIndicator: boolean;
      markPreviousAsRead?: boolean;
      animateIndicator?: boolean;
      animationProgress: number;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    if (typeof ref === 'function') {
      throw new Error('ref must be an object');
    }
    const [carouselProgress, setCarouselProgress] = useState(0);

    useEffect(() => {
      if (!ref?.current) return;

      const element = ref.current;

      const scrollListener = () => {
        const windowScroll = element.scrollLeft;
        const totalWidth = element.scrollWidth - element.clientWidth;

        if (windowScroll === 0) setCarouselProgress(0);
        if (windowScroll > totalWidth) setCarouselProgress(100);
        return setCarouselProgress((windowScroll / totalWidth) * 100);
      };

      element.addEventListener('scroll', scrollListener, {passive: true});
      return () => element.removeEventListener('scroll', scrollListener);
    }, [ref]);

    const activeDot = Math.floor((carouselProgress * count) / 110);
    return (
      <div
        className={clsx(indicatorStyle === 'dot' ? styles.dots : styles.lines)}
      >
        {[...Array(count).keys()].map(i => (
          <div
            key={i}
            className={clsx(
              indicatorStyle === 'dot' ? styles.dot : styles.line,
              onClickIndicator === false && styles.doNotHighlight,
              animateIndicator && styles.animateIndicator
            )}
            data-active={markPreviousAsRead ? activeDot >= i : activeDot === i}
            onClick={() => {
              if (onClickIndicator === false) return;
              scrollToIndex(i);
            }}
          >
            {animateIndicator && activeDot === i && (
              <span style={{width: `${animationProgress}%`}} />
            )}
          </div>
        ))}
      </div>
    );
  }
);

ScrollIndicators.displayName = 'ScrollIndicators';
