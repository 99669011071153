import FeedCard from '@/view/components/FeedCard/FeedCard';
import React, {Suspense} from 'react';
import {graphql, useLazyLoadQuery, usePagination} from '@/kits/relay-kit/src';
import Skeleton from '@/view/components/Skeleton';
import {useCreateStatus} from '@/state/hooks/createStatus';
import InfiniteScrollContainer from '@/view/components/InfiniteScrollContainer/InfiniteScrollContainer';
import {FILTERS} from '../..';
import EmptyProfileCard from '@/view/components/EmptyProfileCards/EmptyProfileCard';
import type {HomeTabFilteredFeedEventsQuery as HomeTabFilteredFeedEventsQueryType} from './__generated__/HomeTabFilteredFeedEventsQuery.graphql';
import {HomeTabFilteredFeedEventsPaginated$key} from './__generated__/HomeTabFilteredFeedEventsPaginated.graphql';
import {HomeTabFilteredFeedEventsPaginatedQuery} from './__generated__/HomeTabFilteredFeedEventsPaginatedQuery.graphql';

const getFilter = (filter: FILTERS) => {
  switch (filter) {
    case FILTERS.POSTS:
      return 'post';
    case FILTERS.MUSIC:
      return 'audio';
    case FILTERS.VIDEO:
      return 'video';
    case FILTERS.ARTWORK:
      return 'image';
    default:
      return '';
  }
};

const HomeTabFilteredFeedEventsQuery = graphql`
  query HomeTabFilteredFeedEventsQuery($type: String!) {
    ...HomeTabFilteredFeedEventsPaginated @arguments(type: $type)
  }
`;

export default function HomeTabFilteredFeed({filter}: {filter: FILTERS}) {
  const {data, retry, error} =
    useLazyLoadQuery<HomeTabFilteredFeedEventsQueryType>(
      HomeTabFilteredFeedEventsQuery,
      {
        type: getFilter(filter),
      }
    );

  const {status} = useCreateStatus();

  React.useEffect(() => {
    if (status === 'done') {
      const timeout = setTimeout(() => {
        retry();
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [status, retry]);

  return !error && <Posts posts={data} filter={filter} />;
}

const HomeTabFilteredFeedEventsPaginated = graphql`
  fragment HomeTabFilteredFeedEventsPaginated on Query
  @argumentDefinitions(
    type: {type: "String!"}
    first: {type: "Int", defaultValue: 10}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "HomeTabFilteredFeedEventsPaginatedQuery") {
    get_filtered_timeline_events(
      type: $type
      filter: {type: {eq: create_tezos_token}}
      orderBy: {created_at: DescNullsLast}
      first: $first
      after: $after
    )
      @connection(
        key: "HomeTabFilteredFeedEventsPaginated_get_filtered_timeline_events"
      ) {
      edges {
        node {
          nodeId
          id
          ...FeedCardEventFragment @arguments(includeToken: true)
        }
      }
    }
  }
`;

const Posts = ({
  posts,
  filter,
}: {
  posts: HomeTabFilteredFeedEventsPaginated$key | null | undefined;
  filter: FILTERS;
}) => {
  const {data, hasNext, loadNext, isLoadingNext, isLoading} = usePagination<
    HomeTabFilteredFeedEventsPaginatedQuery,
    HomeTabFilteredFeedEventsPaginated$key
  >(HomeTabFilteredFeedEventsPaginated, posts);

  return (
    <>
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading
            ? () => {
                hasNext && loadNext(10);
              }
            : undefined
        }
        styleOverrides={{
          position: 'absolute',
          minHeight: '50vh',
          bottom: '100vh',
          left: '0px',
          right: '0px',
          opacity: 0,
          zIndex: '-1',
        }}
      >
        {data?.get_filtered_timeline_events?.edges?.map((edge, i) => {
          const isLast =
            i === (data.get_filtered_timeline_events?.edges?.length || 0) - 1;
          return (
            <Suspense
              key={(edge.node.id || edge.node.nodeId || '') + i + '-suspense'}
              fallback={
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={'min(100vw, 600px)'}
                  height={780}
                  styles={{
                    maxWidth: '100vw',
                  }}
                />
              }
            >
              <FeedCard
                eventKey={edge.node}
                key={(edge.node.id || edge.node.nodeId || '') + i}
              />
              {!isLast && (
                <hr
                  key={
                    (edge.node.id || edge.node.nodeId || '') + i + '-separator'
                  }
                />
              )}
            </Suspense>
          );
        })}
        {!data?.get_filtered_timeline_events?.edges.length && (
          <EmptyProfileCard
            title={`There is no ${(FILTERS[filter] === 'POSTS'
              ? FILTERS[filter].slice(0, -1)
              : FILTERS[filter]
            ).toLocaleLowerCase()} created yet.`}
            small
          />
        )}
      </InfiniteScrollContainer>
      {(isLoadingNext || isLoading) && (
        <Skeleton
          variant="rect"
          animation="wave"
          width={'min(100vw, 600px)'}
          height={780}
          styles={{
            maxWidth: '100vw',
          }}
        />
      )}
    </>
  );
};
