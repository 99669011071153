import React, {Suspense} from 'react';
import styles from '@/view/styles/components/FTUESteps/HomeFtueSteps.module.scss';
import Accordion from '@/view/components/Accordion/Accordion';
import RemixIcon from '@/view/components/RemixIcon';
import ScrollableContainer from '@/view/components/ScrollableContainer';
import {useAccount} from '@/kits/account-kit/src';
import {graphql, useFragment, useLazyLoadQuery} from '@/kits/relay-kit/src';
import UserCard from '@/view/components/UserCard/UserCard';
import Button from '@/view/components/Button';
import {CreateDialog} from '@/view/components/CMDk/Create/CreateDialog';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {useRouter} from 'next/router';
import type {HomeFtueStepsQuery as HomeFtueStepsQueryType} from './__generated__/HomeFtueStepsQuery.graphql';
import {HomeFtueStepsCardFragment$key} from './__generated__/HomeFtueStepsCardFragment.graphql';
import type {HomeFtueStepsSuggestedAccountsQuery as HomeFtueStepsSuggestedAccountsQueryType} from './__generated__/HomeFtueStepsSuggestedAccountsQuery.graphql';

const HomeFtueStepsQuery = graphql`
  query HomeFtueStepsQuery($accountId: BigInt!, $identityId: BigInt!) {
    created: eventsCollection(
      first: 1
      filter: {type: {eq: create_tezos_token}, account_id: {eq: $accountId}}
    ) {
      edges {
        node {
          id
        }
      }
    }
    collected: eventsCollection(
      first: 3
      filter: {
        type: {
          in: [
            purchase_tezos_token
            pending_purchase_tezos_token
            mint_tezos_token
            pending_mint_tezos_token
            collect_for_free
            mint_for_free
          ]
        }
        account_id: {eq: $accountId}
      }
    ) {
      edges {
        node {
          id
        }
      }
    }
    followed: followsCollection(
      first: 5
      filter: {follower_id: {eq: $identityId}}
    ) {
      edges {
        node {
          followee_id
        }
      }
    }
  }
`;

export default function HomeFtueSteps() {
  const {isMobile} = useBreakpoint();
  const router = useRouter();

  const {getAccount} = useAccount();
  const user = getAccount();

  const identityId = user.isLoggedIn ? user.identityId : undefined;
  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const {data, retry} = useLazyLoadQuery<HomeFtueStepsQueryType>(
    HomeFtueStepsQuery,
    {
      accountId: accountId || '',
      identityId: identityId || '',
    },
    {
      skip:
        !user.isLoggedIn ||
        !identityId ||
        !accountId ||
        accountId === '' ||
        identityId === '',
    }
  );

  React.useEffect(() => {
    retry();
  }, []);

  const totalCompleted = [
    (data?.followed?.edges.length || 0) >= 5,
    (data?.created?.edges?.length || 0) >= 1,
  ].filter(Boolean).length;

  const accordionItems: {
    value: string;
    header: React.ReactNode;
    content: React.ReactNode;
    disabled?: boolean;
  }[] = [
    {
      value: 'follow-5-people',
      header: (
        <div
          className={styles.accordionHeader}
          data-complete={(data?.followed?.edges.length || 0) >= 5}
        >
          <RemixIcon
            icon={
              (data?.followed?.edges.length || 0) >= 5
                ? 'check-line'
                : 'user-add-fill'
            }
            size={24}
          />
          <span>Follow 5 people</span>
        </div>
      ),
      content: (
        <div className={styles.accordionContent}>
          <span>Follow people that inspire you</span>
          <Suspense fallback={<></>}>
            <FTUESuggestedAccounts className={styles.ftueSuggestedAccounts} />
          </Suspense>
        </div>
      ),
      disabled: (data?.followed?.edges.length || 0) >= 5,
    },
    {
      value: 'create-a-post',
      header: (
        <div
          className={styles.accordionHeader}
          data-complete={(data?.created?.edges?.length || 0) >= 1}
        >
          <RemixIcon
            icon={
              (data?.created?.edges?.length || 0) >= 1
                ? 'check-line'
                : 'image-add-fill'
            }
            size={24}
          />
          <span>Create a post</span>
        </div>
      ),
      content: (
        <div className={styles.accordionContent}>
          <span>Create your first post on DNS, it&apos;s free!</span>
          <div className={styles.leftPadding}>
            <CreateDialog
              trigger={
                <Button
                  accent
                  onClick={() => {
                    if (isMobile) {
                      router.push(
                        {pathname: router.pathname, query: {...router.query}},
                        router.asPath + '#',
                        {
                          shallow: true,
                        }
                      );
                    }
                  }}
                  className={styles.createPostButton}
                >
                  <RemixIcon icon="add-fill" size={18} />
                  <span>Create a post</span>
                </Button>
              }
            />
          </div>
        </div>
      ),
      disabled: (data?.created?.edges?.length || 0) >= 1,
    },
  ];
  return (
    totalCompleted < accordionItems.length && (
      <>
        <div className={styles.root}>
          <div className={styles.info}>
            <div>Getting Started</div>
            <span>
              {`Here are a few steps to get your profile ready.\nYou'll be up and running in no time.`}
            </span>
            <div>{totalCompleted}/4 complete</div>
          </div>
          <Accordion
            openMultiple
            openByDefault
            fullWidthTrigger
            items={accordionItems}
            className={styles.accordion}
          />
        </div>
        <hr />
      </>
    )
  );
}

const HomeFtueStepsSuggestedAccountsQuery = graphql`
  query HomeFtueStepsSuggestedAccountsQuery {
    featured_accountsCollection(first: 6) {
      edges {
        node {
          id
          nodeId
          accounts {
            id
            nodeId
            ...HomeFtueStepsCardFragment
          }
        }
      }
    }
  }
`;

function FTUESuggestedAccounts({className}: {className?: string}) {
  const {data} = useLazyLoadQuery<HomeFtueStepsSuggestedAccountsQueryType>(
    HomeFtueStepsSuggestedAccountsQuery,
    {}
  );
  return (
    <ScrollableContainer
      cols={3}
      fullWidth
      className={className}
      variant="transparent"
    >
      {data?.featured_accountsCollection?.edges?.map((user, i) => {
        return <Card key={user.node.id} userKey={user.node.accounts} />;
      })}
    </ScrollableContainer>
  );
}

const HomeFtueStepsCardFragment = graphql`
  fragment HomeFtueStepsCardFragment on accounts {
    id
    nodeId
    ...UserCardFragment
  }
`;

const Card = ({userKey}: {userKey: HomeFtueStepsCardFragment$key}) => {
  const user = useFragment(HomeFtueStepsCardFragment, userKey);
  return <UserCard userKey={user} showFollowButton />;
};
