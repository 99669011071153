import FeedCard from '@/view/components/FeedCard/FeedCard';
import React, {Suspense} from 'react';
import {
  commitLocalStoreUpdate,
  graphql,
  useLazyLoadQuery,
  usePagination,
} from '@/kits/relay-kit/src';
import Skeleton from '@/view/components/Skeleton';
import {useAccount} from '@/kits/account-kit/src';
import {useCreateStatus} from '@/state/hooks/createStatus';
import InfiniteScrollContainer from '@/view/components/InfiniteScrollContainer/InfiniteScrollContainer';
import {useOnchainActionStatus} from '@/state/hooks/onChainActionStatus';
import EmptyProfileCard from '@/view/components/EmptyProfileCards/EmptyProfileCard';
import HomeFtueSteps from '@/view/components/FTUESteps/HomeFtueSteps';
import {FollowingTabFeedEventsPaginated$key} from './__generated__/FollowingTabFeedEventsPaginated.graphql';
import {FollowingTabFeedEventsPaginatedQuery} from './__generated__/FollowingTabFeedEventsPaginatedQuery.graphql';
import type {FollowingTabFeedEventsQuery as FollowingTabFeedEventsQueryType} from './__generated__/FollowingTabFeedEventsQuery.graphql';

const FollowingTabFeedEventsQuery = graphql`
  query FollowingTabFeedEventsQuery($identityId: Int!) {
    ...FollowingTabFeedEventsPaginated @arguments(identityId: $identityId)
  }
`;

export default function FollowingTabFeed() {
  const {getAccount} = useAccount();
  const user = getAccount();

  const identityId = user.isLoggedIn ? user.identityId : undefined;
  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const {data, retry} = useLazyLoadQuery<FollowingTabFeedEventsQueryType>(
    FollowingTabFeedEventsQuery,
    {
      identityId: identityId ? Number(identityId) : 1,
    },
    {
      skip: !identityId || identityId === '',
    }
  );

  const {status} = useCreateStatus();
  const {actions} = useOnchainActionStatus();

  React.useEffect(() => {
    if (
      status === 'done' ||
      (actions.length > 0 && actions.every(a => a.status === 'done'))
    ) {
      const timeout = setTimeout(() => {
        retry();
        commitLocalStoreUpdate(updater => {
          updater
            .get(
              `client:root:eventsCollection(filter:{"account_id":{"eq":"${accountId}"},"type":{"neq":"follow"}},first:5,orderBy:{"created_at":"DescNullsLast"})`
            )
            ?.invalidateRecord();
        });
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [status, actions, retry, accountId]);

  return <Posts posts={data} />;
}

const FollowingTabFeedEventsPaginated = graphql`
  fragment FollowingTabFeedEventsPaginated on Query
  @argumentDefinitions(
    identityId: {type: "Int!"}
    first: {type: "Int", defaultValue: 10}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "FollowingTabFeedEventsPaginatedQuery") {
    get_following_tab_events(
      identity_id: $identityId
      orderBy: {created_at: DescNullsLast}
      first: $first
      after: $after
    )
      @connection(
        key: "FollowingTabFeedEventsPaginated_get_following_tab_events"
      ) {
      edges {
        node {
          id
          nodeId
          ...FeedCardEventFragment @arguments(includeToken: true)
        }
      }
    }
  }
`;

const Posts = ({
  posts,
}: {
  posts: FollowingTabFeedEventsPaginated$key | null | undefined;
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();

  const {data, hasNext, loadNext, isLoadingNext, isLoading} = usePagination<
    FollowingTabFeedEventsPaginatedQuery,
    FollowingTabFeedEventsPaginated$key
  >(FollowingTabFeedEventsPaginated, posts);

  if (!data?.get_following_tab_events?.edges.length) {
    return <EmptyProfileCard title="No posts yet, create something!" small />;
  }

  return (
    <>
      {user.isLoggedIn && (
        <Suspense
          fallback={
            <Skeleton
              variant="rect"
              animation="wave"
              borderRadius={10}
              height={420}
              styles={{
                maxWidth: '600px',
              }}
            />
          }
        >
          <HomeFtueSteps />
        </Suspense>
      )}
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading
            ? () => {
                hasNext && loadNext(10);
              }
            : undefined
        }
        styleOverrides={{
          position: 'absolute',
          minHeight: '50vh',
          bottom: '100vh',
          left: '0px',
          right: '0px',
          opacity: 0,
          zIndex: '-1',
        }}
      >
        {data?.get_following_tab_events?.edges?.map((edge, i) => {
          const isLast =
            i === (data.get_following_tab_events?.edges?.length || 0) - 1;
          return (
            <Suspense
              key={(edge.node.id || edge.node.nodeId || '') + i + '-suspense'}
              fallback={
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={'min(100vw, 600px)'}
                  height={780}
                  styles={{
                    maxWidth: '100vw',
                  }}
                />
              }
            >
              <FeedCard
                eventKey={edge.node}
                key={(edge.node.id || edge.node.nodeId || '') + i}
              />
              {!isLast && (
                <hr
                  key={
                    (edge.node.id || edge.node.nodeId || '') + i + '-separator'
                  }
                />
              )}
            </Suspense>
          );
        })}
      </InfiniteScrollContainer>
      {(isLoadingNext || isLoading) && (
        <Skeleton
          variant="rect"
          animation="wave"
          width={'min(100vw, 600px)'}
          height={780}
          styles={{
            maxWidth: '100vw',
          }}
        />
      )}
    </>
  );
};
