import styles from '@/view/styles/pages/home/tabs/blocs/FeaturedTab/FeaturedTrendingTab.module.scss';
import React from 'react';
import {graphql, useLazyLoadQuery, usePagination} from '@/kits/relay-kit/src';
import PostCard from '@/view/components/PostCard/PostCard';
import InfiniteScrollContainer from '@/view/components/InfiniteScrollContainer/InfiniteScrollContainer';
import EmptyProfileCard from '@/view/components/EmptyProfileCards/EmptyProfileCard';
import type {
  FeaturedTrendingTabTokensQuery$data,
  FeaturedTrendingTabTokensQuery as FeaturedTrendingTabTokensQueryType,
} from './__generated__/FeaturedTrendingTabTokensQuery.graphql';
import {FeaturedTrendingTabTokensPaginatedQuery} from './__generated__/FeaturedTrendingTabTokensPaginatedQuery.graphql';
import {FeaturedTrendingTabTokensPaginated$key} from './__generated__/FeaturedTrendingTabTokensPaginated.graphql';

const FeaturedTrendingTabTokensQuery = graphql`
  query FeaturedTrendingTabTokensQuery(
    $types: [enum_token_type!]
    $orderBy: OrderByDirection
  ) {
    tezos_tokensCollection(
      filter: {type: {in: $types}}
      orderBy: {created_at: $orderBy}
      first: 30
    ) {
      edges {
        node {
          nodeId
          id
          ...PostCardTokenFragment @arguments(includeDefault: true)
        }
      }
    }
    ...FeaturedTrendingTabTokensPaginated
      @arguments(types: $types, orderBy: $orderBy)
  }
`;

const FeaturedTrendingTabTokensPaginated = graphql`
  fragment FeaturedTrendingTabTokensPaginated on Query
  @argumentDefinitions(
    types: {type: "[enum_token_type!]"}
    orderBy: {type: "OrderByDirection"}
    first: {type: "Int", defaultValue: 30}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "FeaturedTrendingTabTokensPaginatedQuery") {
    tezos_tokensCollection(
      filter: {type: {in: $types}}
      orderBy: {created_at: $orderBy}
      first: $first
      after: $after
    )
      @connection(
        key: "FeaturedTrendingTabTokensPaginated_tezos_tokensCollection"
      ) {
      edges {
        node {
          nodeId
          id
          ...PostCardTokenFragment @arguments(includeDefault: true)
        }
      }
    }
  }
`;

export default function FeaturedTrendingTab() {
  const {data: recentReleases, isLoading: recentReleasesLoading} =
    useLazyLoadQuery<FeaturedTrendingTabTokensQueryType>(
      FeaturedTrendingTabTokensQuery,
      {
        orderBy: 'DescNullsLast',
        types: ['video', 'audio'],
      }
    );

  return (
    !recentReleasesLoading && (
      <div className={styles.root}>
        <Posts tokens={recentReleases} />
      </div>
    )
  );
}

const Posts = ({
  tokens,
}: {
  tokens: FeaturedTrendingTabTokensQuery$data | null | undefined;
}) => {
  const {
    data: paginatedTokens,
    hasNext,
    loadNext,
    isLoadingNext,
    isLoading,
  } = usePagination<
    FeaturedTrendingTabTokensPaginatedQuery,
    FeaturedTrendingTabTokensPaginated$key
  >(FeaturedTrendingTabTokensPaginated, tokens);

  return (
    <>
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading
            ? () => {
                loadNext(20);
              }
            : undefined
        }
        styleOverrides={{
          width: '100%',
          height: '100px',
          gridColumn: '1 / -1',
        }}
      >
        {(paginatedTokens?.tezos_tokensCollection?.edges?.length || 0) > 0 ? (
          paginatedTokens?.tezos_tokensCollection?.edges.map((edge, i) => {
            return (
              edge.node && (
                <div key={edge.node.id}>
                  <PostCard view="default-library" tokenKey={edge.node} />
                </div>
              )
            );
          })
        ) : (
          <EmptyProfileCard
            title="No items found"
            className={styles.empty}
            small
          />
        )}
      </InfiniteScrollContainer>
    </>
  );
};
