import React from 'react';
import styles from '@/view/styles/components/SuggestedAccounts.module.scss';
import {Avatar} from './Avatar';
import FollowButton from './FollowButton';
import HoverCard from './HoverCard/HoverCard';
import Link from 'next/link';
import RemixIcon from './RemixIcon';
import {graphql, useFragment, useLazyLoadQuery} from '@/kits/relay-kit/src';
import {getImageUrl} from '@/utils/conversions/conversions';
import dynamic from 'next/dynamic';
import {trim} from '@/utils/trim';
import type {SuggestedAccountsQuery as SuggestedAccountsQueryType} from './__generated__/SuggestedAccountsQuery.graphql';
import {SuggestedAccountsItemFragment$key} from './__generated__/SuggestedAccountsItemFragment.graphql';
const UserHoverCard = dynamic(
  () => import('@/view/components/HoverCard/UserHoverCard'),
  {
    ssr: false,
  }
);

const SuggestedAccountsQuery = graphql`
  query SuggestedAccountsQuery {
    featured_accountsCollection {
      edges {
        node {
          id
          accounts {
            id
            nodeId
            ...SuggestedAccountsItemFragment
          }
        }
      }
    }
  }
`;

export default function SuggestedAccounts() {
  const {data} = useLazyLoadQuery<SuggestedAccountsQueryType>(
    SuggestedAccountsQuery,
    {}
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span>SUGGESTED ACCOUNTS</span>
        {/* <Button text>See all</Button> */}
      </div>
      <div className={styles.users}>
        {data?.featured_accountsCollection?.edges.slice(0, 5)?.map(user => (
          <SuggestedAccountItem
            key={user.node.id}
            suggestedUser={user.node.accounts}
          />
        ))}
      </div>
    </div>
  );
}

const SuggestedAccountsItemFragment = graphql`
  fragment SuggestedAccountsItemFragment on accounts {
    id
    nodeId
    type
    identities {
      id
      nodeId
      profilesCollection {
        edges {
          node {
            id
            nodeId
            username
            avatar_uri
            name
          }
        }
      }
    }
    magic_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    beacon_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    teztok_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    ...UserHoverCardFragment
  }
`;

export const SuggestedAccountItem = ({
  suggestedUser: suggestedUserKey,
}: {
  suggestedUser: SuggestedAccountsItemFragment$key | null;
}) => {
  const suggestedUser = useFragment(
    SuggestedAccountsItemFragment,
    suggestedUserKey
  );

  if (!suggestedUser) return null;

  return (
    <div className={styles.user}>
      <Link
        href={`/${
          suggestedUser?.identities?.profilesCollection?.edges?.[0]?.node
            ?.username ||
          suggestedUser?.identities?.id ||
          ''
        }`}
      >
        <HoverCard
          trigger={
            <div>
              {!!suggestedUser.identities.profilesCollection?.edges?.[0].node
                .avatar_uri ? (
                <Avatar
                  src={getImageUrl(
                    suggestedUser.identities.profilesCollection?.edges?.[0].node
                      .avatar_uri || ''
                  )}
                  alt={
                    suggestedUser.identities.profilesCollection?.edges?.[0].node
                      .username || ''
                  }
                  className={styles.avatar}
                >
                  <RemixIcon icon="user-line" />
                </Avatar>
              ) : (
                <span className={styles.avatar}>
                  <RemixIcon icon="user-line" />
                </span>
              )}
              <div className={styles.info}>
                <p>
                  {suggestedUser.identities.profilesCollection?.edges?.[0].node
                    .username ||
                    suggestedUser.identities.profilesCollection?.edges?.[0].node
                      .name ||
                    trim(
                      suggestedUser.type === 'beacon'
                        ? suggestedUser.beacon_accountsCollection?.edges?.[0]
                            ?.node?.public_key_hash || ''
                        : suggestedUser.type === 'magic'
                        ? suggestedUser.magic_accountsCollection?.edges?.[0]
                            ?.node?.public_key_hash || ''
                        : suggestedUser.teztok_accountsCollection?.edges?.[0]
                            ?.node?.public_key_hash || ''
                    ) ||
                    'Unknown User'}
                </p>
                <span>Suggested</span>
              </div>
            </div>
          }
        >
          <UserHoverCard user={suggestedUser} />
        </HoverCard>
      </Link>
      <FollowButton
        id={suggestedUser.identities.id}
        username={
          suggestedUser.identities.profilesCollection?.edges?.[0].node
            .username || ''
        }
        iconOnly
        className={styles.button}
      />
    </div>
  );
};
