import Tabs from '@/view/components/Tabs';
import styles from '@/view/styles/pages/home/tabs/FeaturedTab.module.scss';
import React, {Suspense} from 'react';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {TabType} from '@/view/types/types';
import RemixIcon from '@/view/components/RemixIcon';
import FeaturedAllTab from './blocs/FeaturedTab/FeaturedAllTab';
import InfiniteLoader from '@/view/components/InfiniteLoader';
import FeaturedSalesTab from './blocs/FeaturedTab/FeaturedSalesTab';
import FeaturedTypeTab from './blocs/FeaturedTab/FeaturedTypeTab';
import FeaturedCreatorsTab from './blocs/FeaturedTab/FeaturedCreatorsTab';
import {ErrorBoundary} from 'react-error-boundary';
import {useRouter} from 'next/router';
import Button from '@/view/components/Button';
import FeaturedTrendingTab from './blocs/FeaturedTab/FeaturedTrendingTab';
import HomeCarousel from './blocs/HomeCarousel';
import {useAccount} from '@/kits/account-kit/src';
import TabPanel from '@/view/components/TabPanel';
import {TEAM_MEMBERS} from '@/config';
import FeaturedFreeTab from './blocs/FeaturedTab/FeaturedFreeTab';

const filters: TabType[] = [
  {
    value: 'all',
    label: (
      <div>
        <span>All</span>
      </div>
    ),
  },
  {
    value: 'trending',
    label: (
      <div>
        <RemixIcon icon="star-s-line" size={18} />
        <span>Trending</span>
      </div>
    ),
  },
  {
    value: 'sold',
    label: (
      <div>
        <RemixIcon icon="price-tag-3-line" size={18} />
        <span>Sold</span>
      </div>
    ),
  },
  {
    value: 'art',
    label: (
      <div>
        <RemixIcon icon="artboard-line" size={18} />
        <span>Art</span>
      </div>
    ),
  },
  {
    value: 'music',
    label: (
      <div>
        <RemixIcon icon="play-mini-fill" size={18} />
        <span>Music</span>
      </div>
    ),
  },
  {
    value: 'videos',
    label: (
      <div>
        <RemixIcon icon="video-line" size={18} />
        <span>Videos</span>
      </div>
    ),
  },
  {
    value: 'creators',
    label: (
      <div>
        <RemixIcon icon="user-3-line" size={18} />
        <span>Creators</span>
      </div>
    ),
  },
  {
    value: 'recent',
    label: (
      <div>
        <RemixIcon icon="fire-line" size={18} />
        <span>Recent</span>
      </div>
    ),
  },
];

export default function FeaturedTab() {
  const {getAccount} = useAccount();
  const user = getAccount();

  const [filter, setFilter] = React.useState<number>(() => {
    if (typeof window !== 'undefined') {
      return Number(sessionStorage.getItem('featuredTabLastFilter')) || 0;
    } else {
      return 0;
    }
  });

  const router = useRouter();

  const [error, setError] = React.useState<string | null>(null);

  const {isMobile} = useBreakpoint();

  React.useEffect(() => {
    const onBeforeUnload = () => {
      sessionStorage.setItem('featuredTabLastFilter', (filter || 0).toString());
    };
    router.events.on('routeChangeStart', onBeforeUnload); // this runs on every route change
    window.addEventListener('beforeunload', onBeforeUnload); // this runs on page refresh or tab close
    return () => {
      router.events.off('routeChangeStart', onBeforeUnload);
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [filter]);

  React.useEffect(() => {
    const lastTab = sessionStorage.getItem('featuredTabLastFilter');
    if (lastTab) {
      setFilter(Number(lastTab));
    }
  }, []);

  return (
    <div className={styles.root} data-index={0}>
      {!user.isLoggedIn && isMobile && (
        <HomeCarousel className={styles.carousel} />
      )}
      <Tabs
        variant="chips"
        setTab={t => {
          setFilter(t);
          if (typeof window !== 'undefined') {
            sessionStorage.setItem('featuredTabLastFilter', t.toString());
          }
        }}
        tabs={[
          ...filters,
          ...(user.isLoggedIn && TEAM_MEMBERS.includes(user.accountId)
            ? [{value: 'free', label: 'Free'}]
            : []),
        ]}
        tab={filter || 0}
        className={styles.tabs}
      />
      <TabPanel tab={filter} show={0} className={styles.tabPanel}>
        <ErrorBoundary
          fallback={
            <div
              style={{
                height: '40vh',
                maxWidth: 'unset',
                width: '100%',
                borderRadius: '0px',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--gap-medium, 16px)',
              }}
            >
              <div>Something went wrong</div>
              <span
                style={{
                  color: 'var(--textTertiary)',
                  fontSize: '0.75rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginBottom: '16px',
                }}
              >
                {error}
              </span>
              <Button
                outlined
                onClick={() => {
                  router.reload();
                }}
              >
                <span>Retry</span>
              </Button>
            </div>
          }
          onError={err => {
            setError(err.message);
          }}
        >
          <Suspense
            fallback={<InfiniteLoader height={'90vh'} loaderSize={42} />}
          >
            <FeaturedAllTab />
          </Suspense>
        </ErrorBoundary>
      </TabPanel>
      <TabPanel tab={filter} show={1} className={styles.tabPanel}>
        <ErrorBoundary
          fallback={
            <div
              style={{
                height: '40vh',
                maxWidth: 'unset',
                width: '100%',
                borderRadius: '0px',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--gap-medium, 16px)',
              }}
            >
              <div>Something went wrong</div>
              <span
                style={{
                  color: 'var(--textTertiary)',
                  fontSize: '0.75rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginBottom: '16px',
                }}
              >
                {error}
              </span>
              <Button
                outlined
                onClick={() => {
                  router.reload();
                }}
              >
                <span>Retry</span>
              </Button>
            </div>
          }
          onError={err => {
            setError(err.message);
          }}
        >
          <Suspense
            fallback={<InfiniteLoader height={'90vh'} loaderSize={42} />}
          >
            <FeaturedTrendingTab />
          </Suspense>
        </ErrorBoundary>
      </TabPanel>
      <TabPanel tab={filter} show={2} className={styles.tabPanel}>
        <ErrorBoundary
          fallback={
            <div
              style={{
                height: '40vh',
                maxWidth: 'unset',
                width: '100%',
                borderRadius: '0px',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--gap-medium, 16px)',
              }}
            >
              <div>Something went wrong</div>
              <span
                style={{
                  color: 'var(--textTertiary)',
                  fontSize: '0.75rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginBottom: '16px',
                }}
              >
                {error}
              </span>
              <Button
                outlined
                onClick={() => {
                  router.reload();
                }}
              >
                <span>Retry</span>
              </Button>
            </div>
          }
          onError={err => {
            setError(err.message);
          }}
        >
          <Suspense
            fallback={<InfiniteLoader height={'90vh'} loaderSize={42} />}
          >
            <FeaturedSalesTab />
          </Suspense>
        </ErrorBoundary>
      </TabPanel>
      <TabPanel tab={filter} show={3} className={styles.tabPanel}>
        <ErrorBoundary
          fallback={
            <div
              style={{
                height: '40vh',
                maxWidth: 'unset',
                width: '100%',
                borderRadius: '0px',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--gap-medium, 16px)',
              }}
            >
              <div>Something went wrong</div>
              <span
                style={{
                  color: 'var(--textTertiary)',
                  fontSize: '0.75rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginBottom: '16px',
                }}
              >
                {error}
              </span>
              <Button
                outlined
                onClick={() => {
                  router.reload();
                }}
              >
                <span>Retry</span>
              </Button>
            </div>
          }
          onError={err => {
            setError(err.message);
          }}
        >
          <Suspense
            fallback={<InfiniteLoader height={'90vh'} loaderSize={42} />}
          >
            <FeaturedTypeTab type={['image']} />
          </Suspense>
        </ErrorBoundary>
      </TabPanel>
      <TabPanel tab={filter} show={4} className={styles.tabPanel}>
        <ErrorBoundary
          fallback={
            <div
              style={{
                height: '40vh',
                maxWidth: 'unset',
                width: '100%',
                borderRadius: '0px',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--gap-medium, 16px)',
              }}
            >
              <div>Something went wrong</div>
              <span
                style={{
                  color: 'var(--textTertiary)',
                  fontSize: '0.75rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginBottom: '16px',
                }}
              >
                {error}
              </span>
              <Button
                outlined
                onClick={() => {
                  router.reload();
                }}
              >
                <span>Retry</span>
              </Button>
            </div>
          }
          onError={err => {
            setError(err.message);
          }}
        >
          <Suspense
            fallback={<InfiniteLoader height={'90vh'} loaderSize={42} />}
          >
            <FeaturedTypeTab type={['audio']} />
          </Suspense>
        </ErrorBoundary>
      </TabPanel>
      <TabPanel tab={filter} show={5} className={styles.tabPanel}>
        <ErrorBoundary
          fallback={
            <div
              style={{
                height: '40vh',
                maxWidth: 'unset',
                width: '100%',
                borderRadius: '0px',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--gap-medium, 16px)',
              }}
            >
              <div>Something went wrong</div>
              <span
                style={{
                  color: 'var(--textTertiary)',
                  fontSize: '0.75rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginBottom: '16px',
                }}
              >
                {error}
              </span>
              <Button
                outlined
                onClick={() => {
                  router.reload();
                }}
              >
                <span>Retry</span>
              </Button>
            </div>
          }
          onError={err => {
            setError(err.message);
          }}
        >
          <Suspense
            fallback={<InfiniteLoader height={'90vh'} loaderSize={42} />}
          >
            <FeaturedTypeTab type={['video']} />
          </Suspense>
        </ErrorBoundary>
      </TabPanel>
      <TabPanel tab={filter} show={6} className={styles.tabPanel}>
        <ErrorBoundary
          fallback={
            <div
              style={{
                height: '40vh',
                maxWidth: 'unset',
                width: '100%',
                borderRadius: '0px',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--gap-medium, 16px)',
              }}
            >
              <div>Something went wrong</div>
              <span
                style={{
                  color: 'var(--textTertiary)',
                  fontSize: '0.75rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginBottom: '16px',
                }}
              >
                {error}
              </span>
              <Button
                outlined
                onClick={() => {
                  router.reload();
                }}
              >
                <span>Retry</span>
              </Button>
            </div>
          }
          onError={err => {
            setError(err.message);
          }}
        >
          <Suspense
            fallback={<InfiniteLoader height={'90vh'} loaderSize={42} />}
          >
            <FeaturedCreatorsTab />
          </Suspense>
        </ErrorBoundary>
      </TabPanel>
      <TabPanel tab={filter} show={7} className={styles.tabPanel}>
        <ErrorBoundary
          fallback={
            <div
              style={{
                height: '40vh',
                maxWidth: 'unset',
                width: '100%',
                borderRadius: '0px',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--gap-medium, 16px)',
              }}
            >
              <div>Something went wrong</div>
              <span
                style={{
                  color: 'var(--textTertiary)',
                  fontSize: '0.75rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginBottom: '16px',
                }}
              >
                {error}
              </span>
              <Button
                outlined
                onClick={() => {
                  router.reload();
                }}
              >
                <span>Retry</span>
              </Button>
            </div>
          }
          onError={err => {
            setError(err.message);
          }}
        >
          <Suspense
            fallback={<InfiniteLoader height={'90vh'} loaderSize={42} />}
          >
            <FeaturedTypeTab type={['audio', 'image', 'post', 'video']} />
          </Suspense>
        </ErrorBoundary>
      </TabPanel>
      {user.isLoggedIn && TEAM_MEMBERS.includes(user.accountId) && (
        <TabPanel tab={filter} show={8} className={styles.tabPanel}>
          <ErrorBoundary
            fallback={
              <div
                style={{
                  height: '40vh',
                  maxWidth: 'unset',
                  width: '100%',
                  borderRadius: '0px',

                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 'var(--gap-medium, 16px)',
                }}
              >
                <div>Something went wrong</div>
                <span
                  style={{
                    color: 'var(--textTertiary)',
                    fontSize: '0.75rem',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    marginBottom: '16px',
                  }}
                >
                  {error}
                </span>
                <Button
                  outlined
                  onClick={() => {
                    router.reload();
                  }}
                >
                  <span>Retry</span>
                </Button>
              </div>
            }
            onError={err => {
              setError(err.message);
            }}
          >
            <Suspense
              fallback={<InfiniteLoader height={'90vh'} loaderSize={42} />}
            >
              <FeaturedFreeTab />
            </Suspense>
          </ErrorBoundary>
        </TabPanel>
      )}
    </div>
  );
}
