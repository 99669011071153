import styles from '@/view/styles/pages/home/tabs/blocs/FeaturedTab/FeaturedTrendingTab.module.scss';
import React from 'react';
import {graphql, useLazyLoadQuery, usePagination} from '@/kits/relay-kit/src';
import PostCard from '@/view/components/PostCard/PostCard';
import EmptyProfileCard from '@/view/components/EmptyProfileCards/EmptyProfileCard';
import InfiniteScrollContainer from '@/view/components/InfiniteScrollContainer/InfiniteScrollContainer';
import type {FeaturedSalesTabRecentlySoldTokensQuery as FeaturedSalesTabRecentlySoldTokensQueryType} from './__generated__/FeaturedSalesTabRecentlySoldTokensQuery.graphql';
import {FeaturedSalesTabRecentlySoldTokensPaginated$key} from './__generated__/FeaturedSalesTabRecentlySoldTokensPaginated.graphql';
import {FeaturedSalesTabRecentlySoldTokensPaginatedQuery} from './__generated__/FeaturedSalesTabRecentlySoldTokensPaginatedQuery.graphql';

const FeaturedSalesTabRecentlySoldTokensQuery = graphql`
  query FeaturedSalesTabRecentlySoldTokensQuery($orderBy: OrderByDirection) {
    ...FeaturedSalesTabRecentlySoldTokensPaginated @arguments(orderBy: $orderBy)
  }
`;

export default function FeaturedSalesTab() {
  const {data: recentlySold, isLoading: recentlySoldLoading} =
    useLazyLoadQuery<FeaturedSalesTabRecentlySoldTokensQueryType>(
      FeaturedSalesTabRecentlySoldTokensQuery,
      {
        orderBy: 'DescNullsLast',
      }
    );

  return (
    !recentlySoldLoading && (
      <div className={styles.root}>
        <Posts tokensKey={recentlySold} />
      </div>
    )
  );
}

const FeaturedSalesTabRecentlySoldTokensPaginated = graphql`
  fragment FeaturedSalesTabRecentlySoldTokensPaginated on Query
  @argumentDefinitions(
    orderBy: {type: "OrderByDirection"}
    first: {type: "Int", defaultValue: 30}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "FeaturedSalesTabRecentlySoldTokensPaginatedQuery") {
    get_recently_sold_tokens(
      orderBy: {created_at: $orderBy}
      first: $first
      after: $after
    )
      @connection(
        key: "FeaturedSalesTabRecentlySoldTokensPaginatedQuery_get_recently_sold_tokens"
      ) {
      edges {
        node {
          nodeId
          id
          ...PostCardTokenFragment @arguments(includeDefault: true)
        }
      }
    }
  }
`;

const Posts = ({
  tokensKey,
}: {
  tokensKey: FeaturedSalesTabRecentlySoldTokensPaginated$key | null | undefined;
}) => {
  const {
    data: paginatedTokens,
    hasNext,
    loadNext,
    isLoadingNext,
    isLoading,
  } = usePagination<
    FeaturedSalesTabRecentlySoldTokensPaginatedQuery,
    FeaturedSalesTabRecentlySoldTokensPaginated$key
  >(FeaturedSalesTabRecentlySoldTokensPaginated, tokensKey);

  return (
    <>
      <InfiniteScrollContainer
        loadMore={
          hasNext && !isLoadingNext && !isLoading
            ? () => {
                loadNext(20);
              }
            : undefined
        }
        styleOverrides={{
          width: '100%',
          height: '100px',
          gridColumn: '1 / -1',
        }}
      >
        {(paginatedTokens?.get_recently_sold_tokens?.edges?.length || 0) > 0 ? (
          paginatedTokens?.get_recently_sold_tokens?.edges.map((edge, i) => {
            return (
              edge.node && (
                <div key={edge.node.id}>
                  <PostCard view="default-library" tokenKey={edge.node} />
                </div>
              )
            );
          })
        ) : (
          <EmptyProfileCard
            title="No items found"
            className={styles.empty}
            small
          />
        )}
      </InfiniteScrollContainer>
    </>
  );
};
