import styles from '@/view/styles/pages/home/tabs/HomePageContent.module.scss';
import React from 'react';
import {TabType} from '@/view/types/types';
import Tabs from '@/view/components/Tabs';
import HomeCarousel from './blocs/HomeCarousel';
import FeaturedTab from './FeaturedTab';
import HomeTab from './HomeTab';
import {useAccount} from '@/kits/account-kit/src';
import {useRouter} from 'next/router';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import TabPanel from '@/view/components/TabPanel';
import FollowingTab from './FollowingTab';
import EmptyProfileCard from '@/view/components/EmptyProfileCards/EmptyProfileCard';

const tabs: TabType[] = [
  {
    label: 'Featured',
    value: 'featured',
  },
  {
    label: 'For you',
    value: 'timeline',
  },
  {
    label: 'Following',
    value: 'following',
  },
];

export default function HomePageContent() {
  const {getAccount} = useAccount();
  const user = getAccount();

  const router = useRouter();

  const [tab, setTab] = React.useState<number>(() => {
    if (typeof window !== 'undefined') {
      return Number(sessionStorage.getItem('homePageLastTab')) || 0;
    } else {
      return 0;
    }
  });

  const slideRef = React.useRef<HTMLDivElement>(null);

  const {isMobile} = useBreakpoint();

  const scrollIntoView = (tab: number) => {
    const current = slideRef.current;
    if (!current) return;
    const child = current.children[tab];
    if (!child) return;
    child.scrollIntoView({
      behavior: 'instant',
      block: 'start',
      inline: 'start',
    });
  };

  const scrollToTop = (tab: number) => {
    const current = slideRef.current;
    if (!current) return;
    const child = current.children[tab];
    if (!child) return;
    child.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const updateFilterAfterTimeout = (tab: number) => {
    setTab(tab);
  };

  React.useEffect(() => {
    if (!isMobile) {
      return;
    }
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            updateFilterAfterTimeout(
              Number(entry.target.getAttribute('data-index'))
            );
          }
        });
      },
      {
        root: slideRef.current,
        threshold: 0.4,
      }
    );

    const children = slideRef.current?.children;
    if (children) {
      for (let i = 0; i < children.length; i++) {
        observer.observe(children[i]);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [slideRef, isMobile]);

  React.useEffect(() => {
    const onBeforeUnload = () => {
      sessionStorage.setItem('homePageLastTab', (tab || 0).toString());
      sessionStorage.setItem(
        'homePageScrollPosition',
        (window.scrollY || document.documentElement.scrollTop || 0).toString()
      );
    };
    router.events.on('routeChangeStart', onBeforeUnload); // this runs on every route change
    window.addEventListener('beforeunload', onBeforeUnload); // this runs on page refresh or tab close
    return () => {
      router.events.off('routeChangeStart', onBeforeUnload);
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [tab, router.events]);

  React.useEffect(() => {
    const lastTab = sessionStorage.getItem('homePageLastTab');
    const scrollPosition = sessionStorage.getItem('homePageScrollPosition');
    if (lastTab !== null) {
      setTab(Number(lastTab));
    }
    if (scrollPosition !== null) {
      window.scrollTo(0, Number(scrollPosition));
    }
  }, []);

  return (
    <>
      {!user.isLoggedIn && !isMobile && <HomeCarousel />}
      <div className={styles.tabs}>
        <Tabs
          setTab={t => {
            setTab(t);
            if (Number(t) === Number(tab)) {
              scrollToTop(t);
            }
            if (typeof window !== 'undefined') {
              sessionStorage.setItem(
                'homePageScrollPosition',
                (window.scrollY || 0).toString()
              );
              sessionStorage.setItem('homePageLastTab', t.toString());
            }
          }}
          tabs={tabs?.map(t => ({
            ...t,
            disabled: t.value === 'following' && !user.isLoggedIn,
          }))}
          tab={tab || 0}
        />
      </div>
      <div className={styles.root}>
        <TabPanel tab={tab} show={0} className={styles.tabPanel}>
          <FeaturedTab />
        </TabPanel>
        <TabPanel tab={tab} show={1} className={styles.tabPanel}>
          <HomeTab />
        </TabPanel>
        <TabPanel tab={tab} show={2} className={styles.tabPanel}>
          {user.isLoggedIn ? (
            <FollowingTab />
          ) : (
            <EmptyProfileCard title="Please log in to view" />
          )}
        </TabPanel>
      </div>
    </>
  );
}
