/**
 * @generated SignedSource<<f44b3bc9210e7e44170821164e2172d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeaturedCreatorsTabAllAccountsPaginated$data = {
  readonly accountsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly identities: {
          readonly profilesCollection: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly nodeId: string;
                readonly username: string | null;
              };
            }>;
          } | null;
        };
        readonly nodeId: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserCardFragment">;
      };
    }>;
  } | null;
  readonly " $fragmentType": "FeaturedCreatorsTabAllAccountsPaginated";
};
export type FeaturedCreatorsTabAllAccountsPaginated$key = {
  readonly " $data"?: FeaturedCreatorsTabAllAccountsPaginated$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeaturedCreatorsTabAllAccountsPaginated">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "accountsCollection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 30,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./FeaturedCreatorsTabAllAccountsPaginatedQuery.graphql')
    }
  },
  "name": "FeaturedCreatorsTabAllAccountsPaginated",
  "selections": [
    {
      "alias": "accountsCollection",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "created_at": "DescNullsLast"
          }
        }
      ],
      "concreteType": "accountsConnection",
      "kind": "LinkedField",
      "name": "__FeaturedCreatorsTabAllAccountsPaginated_accountsCollection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "accountsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "accounts",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "identities",
                  "kind": "LinkedField",
                  "name": "identities",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "profilesConnection",
                      "kind": "LinkedField",
                      "name": "profilesCollection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "profilesEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "profiles",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "username",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserCardFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__FeaturedCreatorsTabAllAccountsPaginated_accountsCollection_connection(orderBy:{\"created_at\":\"DescNullsLast\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "7e32347a11fddd9f1e0c0ca960d1e33e";

export default node;
