import styles from '@/view/styles/pages/home/tabs/HomeTab.module.scss';
import Greeting from '@/view/components/Greeting';
import React, {Suspense} from 'react';
import {TabType} from '@/view/types/types';
import SuggestedAccounts from '@/view/components/SuggestedAccounts';
import WhoToFollow from './blocs/WhoToFollow';
import Skeleton from '@/view/components/Skeleton';
import {FILTERS} from '..';
import Tabs from '@/view/components/Tabs';
import {useRouter} from 'next/router';
import {ErrorBoundary} from 'react-error-boundary';
import {useAccount} from '@/kits/account-kit/src';
import RemixIcon from '@/view/components/RemixIcon';
import TabPanel from '@/view/components/TabPanel';
import ProcessingStatusCollect from '@/view/components/ProcessingItems/ProcessingStatusCollect';
import {useOnchainActionStatus} from '@/state/hooks/onChainActionStatus';
import FollowingTabFeed from './blocs/FollowingTabFeed';
import FollowingTabFilteredFeed from './blocs/FollowingTabFilteredFeed';
import {FallbackComponent} from './HomeTab';

const filters: TabType[] = [
  {
    value: 'all',
    label: (
      <div>
        <span>All</span>
      </div>
    ),
  },
  {
    value: 'posts',
    label: (
      <div>
        <RemixIcon icon="article-line" size={18} />
        <span>Posts</span>
      </div>
    ),
  },
  {
    value: 'music',
    label: (
      <div>
        <RemixIcon icon="play-mini-fill" size={18} />
        <span>Music</span>
      </div>
    ),
  },
  {
    value: 'videos',
    label: (
      <div>
        <RemixIcon icon="video-line" size={18} />
        <span>Videos</span>
      </div>
    ),
  },
  {
    value: 'art',
    label: (
      <div>
        <RemixIcon icon="artboard-line" size={18} />
        <span>Art</span>
      </div>
    ),
  },
];

export default function FollowingTab() {
  const {getAccount} = useAccount();
  const user = getAccount();

  const router = useRouter();

  const [filter, setFilter] = React.useState<FILTERS>(() => {
    if (typeof window !== 'undefined') {
      return Number(sessionStorage.getItem('followingTabLastFilter')) || 0;
    } else {
      return 0;
    }
  });

  React.useEffect(() => {
    const onBeforeUnload = () => {
      sessionStorage.setItem(
        'followingTabLastFilter',
        (filter || 0).toString()
      );
    };
    router.events.on('routeChangeStart', onBeforeUnload); // this runs on every route change
    window.addEventListener('beforeunload', onBeforeUnload); // this runs on page refresh or tab close
    return () => {
      router.events.off('routeChangeStart', onBeforeUnload);
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [filter]);

  React.useEffect(() => {
    const lastTab = sessionStorage.getItem('followingTabLastFilter');
    if (lastTab !== null) {
      setFilter(Number(lastTab));
    }
  }, []);

  const [errors, setErrors] = React.useState<{
    all: string;
    posts: string;
    music: string;
    video: string;
    images: string;
  }>({
    all: '',
    posts: '',
    music: '',
    video: '',
    images: '',
  });

  const {actions} = useOnchainActionStatus();

  return (
    <div className={styles.root} data-index={1}>
      <Tabs
        variant="chips"
        setTab={t => {
          setFilter(t);
          if (typeof window !== 'undefined') {
            sessionStorage.setItem('followingTabLastFilter', t.toString());
          }
        }}
        tabs={filters}
        tab={filter || 0}
      />
      <div className={styles.content}>
        <div>
          {actions.length > 0 && (
            <>
              <ProcessingStatusCollect relative />
              <hr className={styles.separator} />
            </>
          )}
          <TabPanel tab={filter} show={FILTERS.ALL} className={styles.tabPanel}>
            <div key={FILTERS.ALL} data-index={FILTERS.ALL}>
              <Suspense
                fallback={
                  <Skeleton
                    variant="text"
                    animation="wave"
                    height={42}
                    width={140}
                    styles={{
                      maxWidth: '100vw',
                    }}
                  />
                }
              >
                <Greeting />
              </Suspense>
              {user.isLoggedIn && (
                <Suspense
                  fallback={
                    <Skeleton
                      variant="rect"
                      animation="wave"
                      borderRadius={10}
                      height={300}
                      styles={{
                        maxWidth: '600px',
                      }}
                    />
                  }
                >
                  <WhoToFollow className={styles.whoToFollow} />
                  <hr />
                </Suspense>
              )}
              <ErrorBoundary
                onError={err => {
                  setErrors(e => ({
                    ...e,
                    all: err.message,
                  }));
                }}
                fallback={<FallbackComponent error={errors.all} />}
              >
                <Suspense
                  fallback={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 'var(--gap-large, 32px)',
                      }}
                    >
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        height={780}
                        styles={{
                          maxWidth: '600px',
                        }}
                      />
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        height={780}
                        styles={{
                          maxWidth: '600px',
                        }}
                      />
                    </div>
                  }
                >
                  <FollowingTabFeed />
                </Suspense>
              </ErrorBoundary>
            </div>
          </TabPanel>
          <TabPanel
            tab={filter}
            show={FILTERS.POSTS}
            className={styles.tabPanel}
          >
            <div key={FILTERS.POSTS} data-index={FILTERS.POSTS}>
              <ErrorBoundary
                onError={err => {
                  setErrors(e => ({
                    ...e,
                    posts: err.message,
                  }));
                }}
                fallback={<FallbackComponent error={errors.posts} />}
              >
                <Suspense
                  fallback={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 'var(--gap-large, 32px)',
                      }}
                    >
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        height={780}
                        styles={{
                          maxWidth: '600px',
                        }}
                      />
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        height={780}
                        styles={{
                          maxWidth: '600px',
                        }}
                      />
                    </div>
                  }
                >
                  <FollowingTabFilteredFeed filter={FILTERS.POSTS} />
                </Suspense>
              </ErrorBoundary>
            </div>
          </TabPanel>
          <TabPanel
            tab={filter}
            show={FILTERS.MUSIC}
            className={styles.tabPanel}
          >
            <div key={FILTERS.MUSIC} data-index={FILTERS.MUSIC}>
              <ErrorBoundary
                onError={err => {
                  setErrors(e => ({
                    ...e,
                    music: err.message,
                  }));
                }}
                fallback={<FallbackComponent error={errors.music} />}
              >
                <Suspense
                  fallback={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 'var(--gap-large, 32px)',
                      }}
                    >
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        height={780}
                        styles={{
                          maxWidth: '600px',
                        }}
                      />
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        height={780}
                        styles={{
                          maxWidth: '600px',
                        }}
                      />
                    </div>
                  }
                >
                  <FollowingTabFilteredFeed filter={FILTERS.MUSIC} />
                </Suspense>
              </ErrorBoundary>
            </div>
          </TabPanel>
          <TabPanel
            tab={filter}
            show={FILTERS.VIDEO}
            className={styles.tabPanel}
          >
            <div key={FILTERS.VIDEO} data-index={FILTERS.VIDEO}>
              <ErrorBoundary
                onError={err => {
                  setErrors(e => ({
                    ...e,
                    video: err.message,
                  }));
                }}
                fallback={<FallbackComponent error={errors.video} />}
              >
                <Suspense
                  fallback={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 'var(--gap-large, 32px)',
                      }}
                    >
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        height={780}
                        styles={{
                          maxWidth: '600px',
                        }}
                      />
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        height={780}
                        styles={{
                          maxWidth: '600px',
                        }}
                      />
                    </div>
                  }
                >
                  <FollowingTabFilteredFeed filter={FILTERS.VIDEO} />
                </Suspense>
              </ErrorBoundary>
            </div>
          </TabPanel>
          <TabPanel
            tab={filter}
            show={FILTERS.ARTWORK}
            className={styles.tabPanel}
          >
            <div key={FILTERS.ARTWORK} data-index={FILTERS.ARTWORK}>
              <ErrorBoundary
                onError={err => {
                  setErrors(e => ({
                    ...e,
                    images: err.message,
                  }));
                }}
                fallback={<FallbackComponent error={errors.images} />}
              >
                <Suspense
                  fallback={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 'var(--gap-large, 32px)',
                      }}
                    >
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        height={780}
                        styles={{
                          maxWidth: '600px',
                        }}
                      />
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        height={780}
                        styles={{
                          maxWidth: '600px',
                        }}
                      />
                    </div>
                  }
                >
                  <FollowingTabFilteredFeed filter={FILTERS.ARTWORK} />
                </Suspense>
              </ErrorBoundary>
            </div>
          </TabPanel>
        </div>
        {user.isLoggedIn && (
          <div className={styles.suggested}>
            <Suspense
              fallback={
                <div
                  style={{
                    width: '270px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: `var(--gap-large, 32px)`,
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Skeleton variant="text" animation="wave" width={120} />
                    <Skeleton variant="text" animation="wave" width={42} />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: `var(--gap-small, 8px)`,
                    }}
                  >
                    <Skeleton
                      variant="circle"
                      animation="wave"
                      width={30}
                      height={30}
                    />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      width={100}
                      height={12}
                      styles={{
                        marginRight: 'auto',
                      }}
                    />
                    <Skeleton
                      variant="circle"
                      animation="wave"
                      width={24}
                      height={24}
                    />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: `var(--gap-small, 8px)`,
                    }}
                  >
                    <Skeleton
                      variant="circle"
                      animation="wave"
                      width={30}
                      height={30}
                    />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      width={100}
                      height={12}
                      styles={{
                        marginRight: 'auto',
                      }}
                    />
                    <Skeleton
                      variant="circle"
                      animation="wave"
                      width={24}
                      height={24}
                    />
                  </div>
                </div>
              }
            >
              <SuggestedAccounts />
            </Suspense>
          </div>
        )}
      </div>
    </div>
  );
}
