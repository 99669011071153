import React from 'react';
import styles from '@/view/styles/components/UserCard/UserCard.module.scss';
import Link from 'next/link';
import Button from '../Button';
import {trim} from '@/utils/trim';
import {Avatar} from '../Avatar';
import Tooltip from '../Tooltip';
import HoverCard from '../HoverCard/HoverCard';
import FollowButton from '../FollowButton';
import {MemoIcon} from '../utils/MemoChildren';
import dynamic from 'next/dynamic';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {getImageUrl} from '@/utils/conversions/conversions';
import Image from '../Image';
import RemixIcon from '../RemixIcon';
import {randomBanner} from '@/utils/images';
import Skeleton from '../Skeleton';
import {UserCardFragment$key} from './__generated__/UserCardFragment.graphql';
import {UserCardBodyFragment$key} from './__generated__/UserCardBodyFragment.graphql';

const UserHoverCard = dynamic(
  () => import('@/view/components/HoverCard/UserHoverCard'),
  {
    ssr: false,
  }
);

const UserCardFragment = graphql`
  fragment UserCardFragment on accounts {
    nodeId
    id
    type
    magic_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
          public_key
        }
      }
    }
    beacon_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
          public_key
        }
      }
    }
    teztok_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
          public_key
        }
      }
    }
    identities {
      id
      nodeId
      profilesCollection {
        edges {
          node {
            nodeId
            id
            username
            avatar_uri
            name
            tezos_profilesCollection {
              edges {
                node {
                  logo
                }
              }
            }
          }
        }
      }
    }
    ...UserHoverCardFragment
    ...UserCardBodyFragment
  }
`;

export default function UserCard({
  userKey,
  showFollowButton,
  removeSuggestion,
  view = 'default',
  observe = false,
}: {
  userKey: UserCardFragment$key | null;
  showFollowButton?: boolean;
  removeSuggestion?: () => void;
  view?: 'mini' | 'default';
  observe?: boolean;
}) {
  const user = useFragment(UserCardFragment, userKey);

  const profileLink = !!user?.identities?.profilesCollection?.edges?.[0]?.node
    ?.username
    ? user?.identities?.profilesCollection?.edges?.[0]?.node?.username
    : user?.type === 'magic'
    ? user?.magic_accountsCollection?.edges?.[0]?.node?.public_key_hash || ''
    : user?.type === 'beacon'
    ? user?.beacon_accountsCollection?.edges?.[0]?.node?.public_key_hash || ''
    : user?.teztok_accountsCollection?.edges?.[0]?.node?.public_key_hash ||
      '' ||
      user?.identities.id;

  const isNonDNSUser = user?.type === 'teztok';

  const username = user?.identities.profilesCollection?.edges?.[0]?.node
    .username
    ? user?.identities.profilesCollection?.edges?.[0]?.node.username
    : user?.identities.profilesCollection?.edges?.[0]?.node.name
    ? user?.identities.profilesCollection?.edges?.[0]?.node.name
    : trim(
        user?.type === 'magic'
          ? user?.magic_accountsCollection?.edges?.[0]?.node?.public_key_hash ||
              ''
          : user?.type === 'beacon'
          ? user?.beacon_accountsCollection?.edges?.[0]?.node
              ?.public_key_hash || ''
          : user?.teztok_accountsCollection?.edges?.[0]?.node
              ?.public_key_hash || ''
      );

  if (view === 'mini') {
    return username ? (
      <Link href={`/${profileLink}`} className={styles.miniCard}>
        <HoverCard
          trigger={
            <div className={styles.miniCardTrigger}>
              {!!(
                user?.identities.profilesCollection?.edges?.[0]?.node
                  .avatar_uri ||
                user?.identities.profilesCollection?.edges?.[0]?.node
                  .tezos_profilesCollection?.edges?.[0]?.node?.logo
              ) ? (
                <Avatar
                  src={getImageUrl(
                    user?.identities.profilesCollection?.edges?.[0]?.node
                      .avatar_uri ||
                      user?.identities.profilesCollection?.edges?.[0]?.node
                        .tezos_profilesCollection?.edges?.[0]?.node?.logo ||
                      ''
                  )}
                  alt=""
                  observe={observe}
                >
                  <MemoIcon Component={'user-line'} size={74} />
                </Avatar>
              ) : (
                <span>
                  <MemoIcon Component={'user-line'} size={74} />
                </span>
              )}
              <div>
                {username}
                {isNonDNSUser && (
                  <Tooltip
                    text="This user is not on DNS"
                    className="non-dns-user-tag"
                  >
                    <span />
                  </Tooltip>
                )}
              </div>
              {showFollowButton && (
                <FollowButton
                  id={user?.identities.id || ''}
                  username={
                    user?.identities.profilesCollection?.edges?.[0]?.node
                      .username || ''
                  }
                  className={styles.followButton}
                />
              )}
            </div>
          }
        >
          <UserHoverCard user={user} />
        </HoverCard>
      </Link>
    ) : null;
  }

  return (
    <Link href={`/${profileLink}`}>
      <HoverCard
        trigger={
          <div className={styles.cardTrigger}>
            <UserCardBody
              userKey={user}
              following={showFollowButton || false}
              removeSuggestion={removeSuggestion}
            />
          </div>
        }
      >
        <UserHoverCard user={user} />
      </HoverCard>
    </Link>
  );
}

const UserCardBodyFragment = graphql`
  fragment UserCardBodyFragment on accounts {
    nodeId
    id
    type
    magic_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
          public_key
        }
      }
    }
    beacon_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
          public_key
        }
      }
    }
    teztok_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
          public_key
        }
      }
    }
    identities {
      id
      nodeId
      profilesCollection {
        edges {
          node {
            nodeId
            id
            username
            avatar_uri
            banner_uri
            name
          }
        }
      }
    }
  }
`;

const UserCardBody = ({
  userKey,
  following,
  removeSuggestion,
}: {
  userKey: UserCardBodyFragment$key | null;
  following: boolean;
  removeSuggestion?: () => void;
}) => {
  const user = useFragment(UserCardBodyFragment, userKey);

  const [bannerLoading, setBannerLoading] = React.useState(true);

  const isNonDNSUser = user?.type === 'teztok';

  return (
    <div className={styles.card}>
      {removeSuggestion && (
        <Button
          icon
          className={styles.removeButton}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            removeSuggestion();
          }}
        >
          <Tooltip text="Remove suggestion">
            <RemixIcon icon="close-line" size={22} />
          </Tooltip>
        </Button>
      )}
      <Image
        className={styles.banner}
        dynamic
        src={
          !!user?.identities.profilesCollection?.edges?.[0].node.banner_uri
            ? getImageUrl(
                user?.identities.profilesCollection?.edges?.[0].node.banner_uri
              )
            : randomBanner(user?.identities.id || '')
        }
        onLoad={() => {
          setBannerLoading(false);
        }}
        onError={e => {
          setBannerLoading(false);
          e.currentTarget.src = randomBanner(user?.identities.id || '');
        }}
        alt={`${user?.id}-banner`}
      />
      {bannerLoading && (
        <Skeleton
          className={styles.banner}
          variant="rect"
          animation="wave"
          styles={{
            position: 'absolute',
            top: 0,
            left: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
      )}
      <div className={styles.content}>
        <Avatar
          src={getImageUrl(
            user?.identities.profilesCollection?.edges?.[0].node.avatar_uri ||
              ''
          )}
          alt={trim(
            user?.identities.profilesCollection?.edges?.[0]?.node.username ||
              user?.id ||
              ''
          )}
          className={styles.avatar}
        >
          <MemoIcon
            Component={'user-line'}
            size={64}
            className={styles.fallback}
          />
        </Avatar>
        <div className={styles.info}>
          <div>
            {user?.identities.profilesCollection?.edges?.[0]?.node.username ||
              user?.identities.profilesCollection?.edges?.[0]?.node.name ||
              trim(
                user?.type === 'magic'
                  ? user?.magic_accountsCollection?.edges?.[0]?.node
                      ?.public_key_hash || ''
                  : user?.type === 'beacon'
                  ? user?.beacon_accountsCollection?.edges?.[0]?.node
                      ?.public_key_hash || ''
                  : user?.teztok_accountsCollection?.edges?.[0]?.node
                      ?.public_key_hash || ''
              )}
            {isNonDNSUser && (
              <Tooltip
                text="This user is not on DNS"
                className="non-dns-user-tag"
              >
                <span />
              </Tooltip>
            )}
          </div>
          <FollowButton
            id={user?.identities.id || ''}
            username={
              user?.identities.profilesCollection?.edges?.[0]?.node.username ||
              ''
            }
          />
        </div>
      </div>
    </div>
  );
};
