import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {randomBanner} from '@/utils/images';
import Button from '@/view/components/Button';
import Carousel from '@/view/components/Carousel';
import Image from '@/view/components/Image';
import styles from '@/view/styles/pages/home/tabs/blocs/HomeCarousel.module.scss';
import clsx from 'clsx';
import React from 'react';

const slides: {
  title: string;
  description: string;
  action: {
    text: string;
    onClick: () => void;
  };
  background: string;
  image: {
    src: string;
    alt: string;
    className?: string;
  };
}[] = [
  {
    title: 'Calling All Creators',
    description:
      'DNS is a next generation platform to publish and sell your latest masterpiece.',
    action: {
      text: 'Get started',
      onClick() {},
    },
    background: '/static/carousel/background/one.webp',
    image: {
      src: '/static/carousel/image/one.webp',
      alt: '',
    },
  },
  {
    title: 'Turn Videos\nInto Payday',
    description: `Upload your videos, let your fans\nstream and buy.\nIt's like Youtube meets Patreon.`,
    action: {
      text: 'Get started',
      onClick() {},
    },
    background: '/static/carousel/background/two.webp',
    image: {
      src: '/static/carousel/image/two.webp',
      alt: '',
    },
  },
  {
    title: 'Play and Sell Music',
    description: `Upload your songs \nand start selling today.\nIt's like Spotify meets Bandcamp.`,
    action: {
      text: 'Get started',
      onClick() {},
    },
    background: '/static/carousel/background/three.webp',
    image: {
      src: '/static/carousel/image/three.webp',
      alt: '',
    },
  },
  {
    title: 'Showcase\nYour Artwork',
    description: `Display and sell your artwork.\nIt's like DeviantArt meets Patreon.`,
    action: {
      text: 'Get started',
      onClick() {},
    },
    background: '/static/carousel/background/four.webp',
    image: {
      src: '/static/carousel/image/four.webp',
      alt: '',
    },
  },
];

export default function HomeCarousel({className}: {className?: string}) {
  return (
    <Carousel
      count={4}
      className={clsx(styles.root, className)}
      autoPlay
      autoPlayInterval={3000}
      clickToSkip
      pauseOnHover
      infinite
    >
      {slides.map((slide, index) => (
        <Slide key={index} {...slide} />
      ))}
    </Carousel>
  );
}

const Slide = ({
  title,
  description,
  action,
  background,
  image,
}: {
  title: string;
  description: string;
  action: {
    text: string;
    onClick: () => void;
  };
  background: string;
  image: {
    src: string;
    alt: string;
    className?: string;
  };
}) => (
  <div className={styles.slide} style={{backgroundImage: `url(${background})`}}>
    <div className={styles.content}>
      <div className={styles.text}>
        <div>
          <div>{title}</div>
          <span>{description}</span>
        </div>
        <Button
          filled
          onClick={() => {
            useLoginModalActions.setShowLoginModal(true);
            action.onClick();
          }}
        >
          <span>{action.text}</span>
        </Button>
      </div>
      <Image
        dynamic
        src={image.src}
        alt={image.alt}
        className={image.className}
      />
    </div>
  </div>
);
